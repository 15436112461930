import { useEffect, useState } from 'react';
import useThemeStore from 'Theme/store';
// Icons
import {
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  PaperClipIcon,
} from '@heroicons/react/24/solid';

const PercentageHandler = ({ attributes, words, onChange, className }) => {
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [startX, setStartX] = useState(0);
  const [startWidth, setStartWidth] = useState(0);
  const [startPercentage, setStartPercentage] = useState(0);

  const { theme: themeValue } = useThemeStore();

  const roundFactor = (num, decimales = 2) => {
    const factor = Math.pow(10, decimales);
    return Math.round(num * factor) / factor;
  };

  const checkIfBorder = (e) => {
    const rect = e.target.getBoundingClientRect();
    const style = getComputedStyle(e.target);
    const borderWidth = parseInt(style.borderRightWidth, 10);

    if (borderWidth > 0) {
      const x = e.clientX - rect.left;
      if (x > rect.width - borderWidth) {
        e.currentTarget.style.cursor = 'col-resize';
        return true;
      } else {
        e.currentTarget.style.cursor = 'default';
        return false;
      }
    } else {
      e.currentTarget.style.cursor = 'default';
      return false;
    }
  };

  const handleMouseDown = (index, event) => {
    if (checkIfBorder(event)) {
      setDraggingIndex(index);
      setStartX(event.pageX);
      setStartWidth(event.target.clientWidth);
      setStartPercentage(Object.values(attributes)[index]);
    }
  };

  const handleMouseMove = (event) => {
    if (draggingIndex !== null) {
      const diff = event.pageX - startX;
      const newWidth = startWidth + diff;
      if (newWidth >= 0) {
        const percentageDiff = diff / event.target.parentElement.clientWidth;
        const newPercentages = { ...attributes };
        const keys = Object.keys(attributes);
        if (newPercentages[keys[draggingIndex + 1]] >= percentageDiff) {
          const currentPercentage = startPercentage + percentageDiff;

          newPercentages[keys[draggingIndex]] = roundFactor(
            currentPercentage,
            4
          );

          if (draggingIndex < keys.length - 1) {
            newPercentages[keys[draggingIndex + 1]] -= roundFactor(
              percentageDiff,
              4
            );
          }
          onChange(newPercentages);
        }
      }
    }
  };

  const handleMouseUp = () => {
    setDraggingIndex(null);
  };

  const getItem = (attribute) => {
    switch (attribute) {
      case 'input':
        return (
          <div className="flex items-center gap-2">
            <BoltIcon className="w-4 h-4 text-raisin-black dark:text-crystal-bell" />
            <div className="font-bold">Current Prompt</div>
          </div>
        );
      case 'files':
        return (
          <div className="flex items-center gap-2">
            <PaperClipIcon className="w-4 h-4 text-raisin-black dark:text-crystal-bell" />
            <div className="font-bold">Attachments</div>
          </div>
        );
      case 'messages':
        return (
          <div className="flex items-center gap-2">
            <ChatBubbleBottomCenterTextIcon className="w-4 h-4 text-raisin-black dark:text-crystal-bell" />
            <div className="font-bold">Conversation History</div>
          </div>
        );
      default:
        return null;
    }
  };

  const getColor = (attribute) => {
    switch (attribute) {
      case 'input':
        return 'rose-quartz';
      case 'files':
        return 'burnt-sienna';
      case 'messages':
        return 'vista-blue';
      default:
        return '';
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [draggingIndex]);

  return (
    <>
      <div className="hidden">
        <div className="bg-vista-blue"></div>
        <div className="bg-rose-quartz"></div>
        <div className="bg-burnt-sienna"></div>
        <div className="bg-vista-blue/10"></div>
        <div className="bg-rose-quartz/10"></div>
        <div className="bg-burnt-sienna/10"></div>
        <div className="border-vista-blue"></div>
        <div className="border-rose-quartz"></div>
        <div className="border-burnt-sienna"></div>
        <div className="text-vista-blue"></div>
        <div className="text-rose-quartz"></div>
        <div className="text-burnt-sienna"></div>
      </div>
      <div className={`flex flex-col ${className}`}>
        <div className="w-full flex mb-4">
          {Object.keys(attributes).map((attribute, index) => (
            <div
              key={index}
              className={`relative bg-${getColor(attribute)} h-4 ${
                index !== Object.keys(attributes).length - 1 &&
                `${
                  themeValue == 'dark'
                    ? 'dark:border-crystal-bell'
                    : 'border-raisin-black'
                } border-r-8`
              } ${index == 0 && 'rounded-l-full'} ${
                index == Object.keys(attributes).length - 1 && 'rounded-r-full'
              }`}
              style={{ width: `${attributes[attribute] * 100}%` }}
              onMouseDown={(event) => handleMouseDown(index, event)}
              onMouseMove={checkIfBorder}
            ></div>
          ))}
        </div>
        <div
          className={`w-fit flex flex-col gap-2 ${
            themeValue == 'dark'
              ? 'dark:text-crystal-bell'
              : 'text-raisin-black'
          } text-sm leading-[22px]`}
        >
          {Object.keys(attributes).map((attribute, index) => (
            <div key={index} className="grid grid-cols-2 gap-6">
              {getItem(attribute)}
              <div className="">
                <span
                  className={`bg-${getColor(
                    attribute
                  )}/10 border-2 border-${getColor(
                    attribute
                  )} rounded-xl py-[2px] px-2 text-xs text-${getColor(
                    attribute
                  )} font-bold`}
                >{`${(attributes[attribute] * 100).toFixed(2)}%`}</span>
                {` of the limit${
                  words == 0
                    ? '.'
                    : `, or around ${Math.floor(
                        words * attributes[attribute]
                      )} words.`
                }`}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PercentageHandler;
