import { useEffect, useMemo, useState } from 'react';
// Icons
import {
  BoltIcon,
  EyeIcon,
  VariableIcon,
  PaperClipIcon,
  Cog6ToothIcon,
  ArrowDownOnSquareIcon,
} from '@heroicons/react/24/outline';
import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
// MUI
import { Button } from '@mui/material';
// State
import usePublicPromptStore from '../State/prompts';
import usePublicLayoutStore from '../State';
// Utils
import { cn } from 'utils/styles';
// React Hook Form
import { FormProvider, useForm } from 'react-hook-form';
// Components
import Tab from 'Components/Common/Tab';
import VariableSection from 'Components/Chat/TemplateModal/components/VariableSection';
import AttachmentsSection from './AttachmentSection';
import ViewPromptSection from 'Components/Chat/TemplateModal/components/ViewPromptSection';
import { AdvancedSection } from 'Components/Chat/TemplateModal/components/AdvancedSection/AdvancedSection';
import { SparklesIcon } from '@heroicons/react/24/solid';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import { validateObjectHasEmptyFields } from 'Components/Chat/TemplateModal/utils/functions';
import useTemplateModalStore from 'Components/Chat/TemplateModal/store';
import ErrorModal from 'Components/Chat/TemplateModal/components/ErrorModal';

const TemplateModalShare = ({ handleSubmitModal }) => {
  const [openModal, setOpenModal] = useState(true);
  // Global state
  const { selectedTool, selectedPrompt, defaultValues } =
    usePublicPromptStore();
  const { openRegisterModal } = usePublicLayoutStore();

  const objectDefaultValues = useMemo(
    () => defaultValues ?? {},
    [defaultValues]
  );

  // Store
  const { setErrorModal, setErrorModalReason } = useTemplateModalStore();

  // Form
  const methods = useForm({});
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (Object.keys(objectDefaultValues).length > 0) {
      reset(objectDefaultValues);
      sessionStorage.setItem(
        'toolShareData',
        JSON.stringify(objectDefaultValues)
      );
    }
  }, [objectDefaultValues]);

  // Set values to session
  useEffect(() => {
    if (Object.keys(methods.watch() ?? {})?.length > 0)
      sessionStorage.setItem('toolShareData', JSON.stringify(methods.watch()));
  }, [methods.watch()]);

  // Global state management
  const handleOnsubmit = (data) => {
    // openRegisterModal();
    sessionStorage.setItem('toolShareData', JSON.stringify(data));
    handleInsert(data);
  };

  const handleInsert = (data) => {
    const attachments = selectedPrompt?.prompts?.filter((i) =>
      ['image', 'attachment'].includes(i.type)
    );

    // Delete for data the keys that exist in the attachments
    attachments?.forEach((attachment) => {
      delete data[attachment.attr];
    });

    // conditions to pass the data
    const emptyFields = validateObjectHasEmptyFields(data);
    const emptyAttachments =
      attachments?.length > 0 &&
      Object.values(methods.watch('files') ?? {}).length === 0;

    if (emptyFields || emptyAttachments) {
      setErrorModal(true);
      setErrorModalReason({
        emptyFields,
        emptyAttachments,
      });
    } else {
      handleSendData();
    }
  };

  const handleSendData = () => {
    handleSubmitModal(Object.entries(methods.watch()), selectedPrompt);
    setOpenModal(false);
  };

  // TABS
  const [currentTab, setCurrentTab] = useState(1);
  const TABS = [
    {
      icon: <VariableIcon className="w-6 h-6" />,
      text: 'Variables',
      value: 1,
      render: () => (
        <VariableSection selectedPrompt={selectedTool?.prompts[0]} />
      ),
    },
    {
      icon: <PaperClipIcon className="w-6 h-6" />,
      text: 'Attachments',
      value: 2,
      render: () => (
        <AttachmentsSection
          selectedPrompt={selectedTool?.prompts[0]}
          selectedTool={selectedTool}
        />
      ),
    },
    {
      icon: <Cog6ToothIcon className="w-6 h-6" />,
      text: 'Advanced',
      value: 3,
      render: () => (
        <AdvancedSection
          options={[
            {
              label: 'Edit template',
              icon: <PencilSquareIcon />,
              onClick: openRegisterModal,
            },
            {
              label: 'Duplicate template',
              icon: <DocumentDuplicateIcon />,
              onClick: openRegisterModal,
            },
            {
              label: 'Delete template',
              icon: <TrashIcon />,
              onClick: openRegisterModal,
            },
          ]}
        />
      ),
    },
    {
      icon: <EyeIcon className="w-6 h-6" />,
      text: 'View prompt',
      value: 4,
      render: () => (
        <ViewPromptSection
          selectedTool={selectedTool}
          promptValue={selectedPrompt[0]?.value}
          formValues={methods.watch()}
        />
      ),
    },
  ];
  const tabs = useMemo(() => {
    if (selectedTool?.files?.length > 0) {
      return TABS;
    }
    return TABS.filter((tab) => tab.value !== 2);
  }, [TABS]);

  const handleChangeTab = (tab) => () => {
    if (tab.disabled) openRegisterModal();
    else setCurrentTab(tab.value);
  };

  return (
    <CommonModal open={openModal} maxWidth="sm" withCloseButton>
      <FormProvider {...methods}>
        <div className="font-figtree bg-seasalt dark:bg-lead text-raisin-black dark:text-crystal-bell">
          <div className="flex gap-2 items-center min-w-[250px] md:min-w-[500px] max-w-screen">
            <div className="flex flex-col">
              <div className="mb-2">
                <p className="line-clamp-1 font-bold uppercase text-sm text-violet-blue">
                  Prompt Template
                </p>
              </div>
              <div className="uppercase text-lg font-bold w-full truncate text-pretty">
                {selectedTool?.title}
              </div>
            </div>
          </div>
          {selectedTool?.multi_default_models.length > 0 ? (
            <div className="flex space-x-2 overflow-x-scroll">
              {selectedTool?.multi_default_models.map((model, index) => (
                <div
                  key={index}
                  className="mt-1 mb-4 bg-nue-blue text-white px-1 rounded"
                  style={{
                    display: 'inline-block',
                    width: 'fit-content',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span className="text-xs font-semibold px-1 text-white">
                    {model.name}
                  </span>
                </div>
              ))}
            </div>
          ) : selectedTool?.default_model ? (
            <div>
              {' '}
              {selectedTool?.default_model &&
                Object.keys(selectedTool?.default_model).length > 0 && (
                  <div
                    className="mt-1 mb-4  bg-nue-blue text-white px-1 rounded"
                    style={{
                      display: 'inline-block',
                      width: 'fit-content',
                    }}
                  >
                    <span className="text-xs font-semibold px-1 text-white">
                      {selectedTool?.default_model?.name}
                    </span>
                  </div>
                )}
            </div>
          ) : (
            <div>{/* Add content here if needed */}</div>
          )}
          <div className="mt-1 mb-4 text-sm italic text-cool-gray dark:text-battleship-gray">
            {selectedTool?.desc}
          </div>
          <div className={cn('grid gap-1', `grid-cols-${tabs.length ?? 1}`)}>
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                text={tab.text}
                value={tab.value}
                currentValue={currentTab}
                onClick={handleChangeTab(tab)}
              />
            ))}
          </div>
          <hr className="mt-4 bg-cool-gray" />
          <div className="mt-4 max-h-[40vh] overflow-y-auto duration-500 ease-in-out">
            {TABS.find((tab) => tab.value === currentTab)?.render()}
          </div>
          <hr className="mt-4 bg-cool-gray" />
          <div className="flex justify-center gap-2 mt-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(handleOnsubmit)}
              sx={{
                color: 'white',
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '24px',
                fontFamily: 'Figtree',
                borderRadius: '12px',
                textTransform: 'none',
                padding: '12px 24px',
                '&.MuiButton-root': {
                  display: 'flex',
                  alignItems: 'flex-start',
                },
              }}
              startIcon={
                <ArrowDownOnSquareIcon className="w-5 h-5 text-white" />
              }
            >
              Use this template
            </Button>
          </div>
        </div>
      </FormProvider>
      <ErrorModal handleConfirm={handleSendData} />
    </CommonModal>
  );
};

export default TemplateModalShare;
