import React from 'react';
import { useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import useThemeStore from 'Theme/store';
import Button from './Button';

const ModalProfileSteps = ({ onClose = () => {}, open }) => {
  const { theme } = useThemeStore();

  return (
    <>
      {open && (
        <div className="fixed inset-x-0 px-4 py-6 inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-raisin-black opacity-55"></div>
          </div>

          <div
            className={`relative font-figtree ${
              theme == 'dark' ? 'bg-lead' : 'bg-seasalt'
            } rounded-xl overflow-auto shadow-xl transform transition-all sm:max-w-lg sm:w-full max-h-full flex flex-col`}
          >
            <div className="px-6 overflow-auto relative">
              <div className="sticky top-0">
                <div className="flex items-center pt-6">
                  {/* <div className="flex-shrink-0 inline-flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 bg-vanilla-300">
                    <UserCircleIcon
                      className={`w-8 h-8 transition text-vanilla-700`}
                    />
                  </div> */}
                  <div className="mt-0 ml-4 w-full">
                    <div
                      className={`text-[28px] leading-7 w-full text-center ${
                        theme == 'dark'
                          ? 'text-crystal-bell'
                          : 'text-raisin-black'
                      } font-bold`}
                    >
                      Customize your avatar
                    </div>
                  </div>
                </div>
              </div>

              <div className="dialog-content-container overflow-auto pr-2">
                <div className="mt-4">
                  <p
                    className={`text-base text-center ${
                      theme == 'dark'
                        ? 'text-crystal-bell'
                        : 'text-raisin-black'
                    }`}
                  >
                    Personalize your avatar using the various options available
                    in Straico and earn coins by completing the task.
                  </p>
                  <div
                    className={`flex items-end justify-center animate-bounce gap-4 mt-5 uppercase font-bold ${
                      theme == 'dark'
                        ? 'text-stargate-shimmer'
                        : 'text-violet-blue'
                    }`}
                  >
                    <p className="font-bold">Win 90 coins</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 flex justify-center items-center w-full px-6 pb-6 bottom-0">
              <Button
                title={'Close'}
                noMarginTop
                customRound="rounded-xl"
                customPaddig="px-4 py-2"
                customWeight="font-semibold"
                noUppercase
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalProfileSteps;
