// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
// MUI
import { Button } from '@mui/material';
// Store
import useTemplateModalStore from '../../store';
// Constants
import { MESSAGE_BODY, MESSAGE_TITLE } from './constants';

const ErrorModal = ({ handleConfirm, sendDirect = false }) => {
  // Store
  const { errorModal, setErrorModal, errorModalReason, setErrorModalReason } =
    useTemplateModalStore();
  // Modal
  const handleClose = () => {
    setErrorModal(false);
    setErrorModalReason({
      emptyFields: false,
      emptyAttachments: false,
    });
  };

  const localHandleConfirm = () => {
    console.log('sendDirect', sendDirect);
    handleConfirm(sendDirect);
    setErrorModal(false);
    setErrorModalReason({
      emptyFields: false,
      emptyAttachments: false,
    });
  };

  const { emptyFields, emptyAttachments } = errorModalReason;

  return (
    <CommonModal
      open={errorModal}
      handleClose={handleClose}
      sx={{ minHeight: 'initial' }}
    >
      <div className="font-figtree max-w-96 text-base text-black dark:text-crystal-bell text-center">
        <div className="w-full flex justify-center">
          <div className="text-raisin-black dark:text-crystal-bell text-[28px] leading-7 font-bold mb-5">
            {`${
              emptyFields && emptyAttachments
                ? MESSAGE_TITLE.both
                : emptyFields
                ? MESSAGE_TITLE.emptyFields
                : MESSAGE_TITLE.emptyAttachments
            }`}
          </div>
        </div>

        <div className="mb-5 text-raisin-black dark:text-crystal-bell">
          {emptyFields && emptyAttachments
            ? MESSAGE_BODY.both
            : emptyFields
            ? MESSAGE_BODY.emptyFields
            : MESSAGE_BODY.emptyAttachments}
        </div>
        <div className="flex w-full gap-4 left-0 items-center justify-center">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleClose}
            fullWidth
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '28px',
              fontFamily: 'Figtree',
              borderRadius: '12px',
              textTransform: 'none',
              padding: '8px 16px',
            }}
          >
            <span className="font-bold">Complete</span>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={localHandleConfirm}
            fullWidth
            sx={{
              fontWeight: 700,
              fontSize: '18px',
              lineHeight: '28px',
              fontFamily: 'Figtree',
              borderRadius: '12px',
              textTransform: 'none',
              padding: '8px 16px',
            }}
          >
            <span className="font-bold">Continue</span>
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default ErrorModal;
