import { useMemo, useState } from 'react';
// Context
import { useChat } from 'Context/Chat/Chat.context';
// Icons
import { PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { DocumentTextIcon, EyeIcon } from '@heroicons/react/24/outline';
// RHF
import { useFormContext } from 'react-hook-form';
// Components
import FileContext from 'Components/FileContext';
import useThemeStore from 'Theme/store';
import useTemplateModalStore from '../../store';

const AttachmentsSection = () => {
  // Context
  const {
    selectedTool, // Template base info
    selectedPrompt, // Template variables and prompt
  } = useChat();

  const { setValue, watch } = useFormContext();
  const { models } = useTemplateModalStore();

  const selectedModel = useMemo(
    () =>
      models.filter((model) => model._id === selectedTool?.default_model?.id),
    [selectedTool?.default_model, models]
  );
  const [open, setOpen] = useState(false);
  const [textAttr, setTextAttr] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  const handleBadInteraction = () => {};
  const handleInteraction = (e) => {
    const file = e[0];

    let content = {};
    // validate if file is a object
    if (typeof file !== 'object') {
      let type = '';
      const youtubeUrlPattern =
        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/i;
      if (youtubeUrlPattern.test(e)) {
        type = 'youtube';
      } else {
        type = 'webpage';
      }

      content = {
        name: file,
        url: file,
        type,
      };
    } else {
      content = file;
    }

    setValue('files', {
      ...watch('files'),
      [textAttr]: content,
    });
    toggleOpen();
  };

  const handleOpen = (attr) => () => {
    setOpen(true);
    setTextAttr(attr);
  };

  const handleRemove = (attr) => () => {
    //delete the file to undefined
    setValue('files', {
      ...watch('files'),
      [attr]: undefined,
    });
  };

  const handleSeeFile = (file) => () => {
    window.open(file?.url ?? file, '_blank');
  };

  const { theme } = useThemeStore();

  return (
    <div>
      <div className="my-2">
        {selectedTool?.files?.length > 0 && (
          <h1 className="font-figtree mb-2 text-base font-semibold text-raisin-black dark:text-crystal-bell">
            Fixed attachments
          </h1>
        )}
        <div className="flex flex-col gap-3 px-6">
          {selectedTool?.files?.map((file) => (
            <div
              key={file?._id}
              className="flex gap-6 items-center justify-around font-figtree text-base leading-[14px] text-raisin-black dark:text-crystal-bell"
            >
              <DocumentTextIcon className="w-5 h-5" />
              <p className="line-clamp-1 w-full mt-0">{file?.name}</p>
              <div className="flex gap-2 items-center">
                {file?.url && (
                  <EyeIcon
                    className="w-5 h-5 cursor-pointer"
                    onClick={handleSeeFile(file)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedPrompt?.prompts?.filter((i) =>
        ['image', 'attachment'].includes(i.type)
      ).length > 0 && (
        <h1 className="font-figtree mb-2 text-base font-semibold text-raisin-black dark:text-crystal-bell">
          Variable attachments
        </h1>
      )}
      {selectedPrompt?.prompts
        ?.filter((i) => ['image', 'attachment'].includes(i.type))
        ?.map((prompt, index) => {
          return (
            <div className="mb-4" key={index}>
              <div className="font-figtree mb-2 text-base font-semibold text-raisin-black dark:text-crystal-bell">
                {prompt?.title}
              </div>
              {(watch('files') ?? {})[prompt.attr] ? (
                <div className="flex flex-col gap-3 px-6">
                  <div className="flex gap-6 items-center justify-around font-figtree text-base leading-[14px] text-raisin-black dark:text-crystal-bell">
                    <DocumentTextIcon className="w-5 h-5" />
                    <p className="line-clamp-1 w-full mt-0">
                      {(watch('files') ?? {})[prompt.attr]?.name ??
                        (watch('files') ?? {})[prompt.attr]}
                    </p>
                    <div className="flex gap-2 items-center">
                      {(watch('files') ?? {})[prompt.attr]?.url && (
                        <EyeIcon
                          className="w-5 h-5 cursor-pointer"
                          onClick={handleSeeFile(
                            (watch('files') ?? {})[prompt.attr]
                          )}
                        />
                      )}
                      <XMarkIcon
                        className="w-5 h-5 cursor-pointer"
                        onClick={handleRemove(prompt.attr)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  onClick={handleOpen(prompt.attr)}
                  className={`cursor-pointer hover:bg-lavender hover:text-violet-blue dark:hover:bg-ship-grey dark:hover:text-tropical-indigo flex items-center gap-2 w-full border border-dashed border-platinum dark:border-palladium px-2 py-1 font-figtree rounded-xl ${
                    theme == 'dark'
                      ? 'bg-night-black dark:text-crystal-bell'
                      : 'bg-ghost-white text-cool-gray'
                  }`}
                >
                  <PlusIcon className="w-5 h-5 cursor-pointer" />
                  <p>Add {prompt.type} here</p>
                </div>
              )}
            </div>
          );
        })}
      {open && (
        <FileContext
          models={selectedModel}
          onInteraction={handleInteraction}
          onClose={toggleOpen}
          onBadInteraction={handleBadInteraction}
        />
      )}
    </div>
  );
};

export default AttachmentsSection;
