import React, { useEffect, useState } from 'react';
import * as HIcons from '@heroicons/react/24/outline';
import { VideoCameraIcon } from '@heroicons/react/24/outline';
import ShortModal from './ShortModal';
import useThemeStore from 'Theme/store';

const Subheader = ({ title, description, loading, icon }) => {
  const { ...icons } = HIcons;
  const TheIcon = icons[icon];

  const [openDialog, setOpenDialog] = useState(false);

  const onInteraction = async (str) => {
    if (str == 'close') {
      setOpenDialog(false);
    }
  };

  const { theme: themeValue } = useThemeStore();

  return (
    <>
      <div className="pt-1 pb-1 border-b border-cool-gray relative text-raisin-black dark:text-crystal-bell">
        <div className="container px-4 flex items-center">
          <div className="flex">
            <div className="mr-2 mt-1">
              {icon ? (
                <TheIcon className="h-5 w-5 px-[1px] py-[2px] bg-lavender rounded-md dark:text-raisin-black"></TheIcon>
              ) : (
                <></>
              )}
            </div>
            <div>
              <div className="flex w-full">
                <h2 className="font-bold text-lg flex align-center uppercase truncate">
                  {title}
                </h2>
              </div>
              {description ? (
                <p
                  className={`text-base ${
                    themeValue == 'dark' ? 'dark:text-white' : 'text-cool-gray'
                  }   md:text-md lg:text-lg mt-1 lg:mx-0`}
                >
                  {description}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="w-full -mt-[10px] -mb-[0.42rem]">
          <progress className="progress progress-accent -mt-8"></progress>
        </div>
      ) : (
        <div className="w-full -mb-[0.42rem]">
          <div className="flex h-[14px]"></div>
        </div>
      )}

      {openDialog ? (
        <ShortModal onInteraction={onInteraction}></ShortModal>
      ) : (
        <></>
      )}
    </>
  );
};

export default Subheader;
