import { cn } from 'utils/styles';

const AvatarSvgIcon = ({ svg, className }) => {
  return (
    <div className="avatar">
      <div className={cn('w-16 rounded', className)}>
        <div dangerouslySetInnerHTML={{ __html: svg }} />
      </div>
    </div>
  );
};

export default AvatarSvgIcon;
