import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Checkbox,
  ClickAwayListener,
  InputAdornment,
  Popper,
  styled,
  TextField,
} from '@mui/material';
import * as React from 'react';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#141114',
}));

export default function SelectAutocomplete({
  options,
  value,
  onChange,
  placeholderSearch,
  classNameSelect,
}) {
  const { theme } = useThemeStore();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pendingValue, setPendingValue] = React.useState([]);

  const handleClick = (event) => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onChange(pendingValue);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const onClickOption = (option) => () => {
    if (pendingValue.find((label) => label.id === option.id))
      setPendingValue(pendingValue.filter((label) => label.id !== option.id));
    else setPendingValue([...pendingValue, option]);
  };

  const [searchValue, setSearchValue] = React.useState('');
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'label-selector' : undefined;

  const memoOptions = React.useMemo(() => {
    if (searchValue) {
      const regex = new RegExp(searchValue, 'i');
      return options.filter((item) => regex.test(item.name.toLowerCase()));
    } else {
      return options;
    }
  }, [options, searchValue]);

  return (
    <React.Fragment>
      <Box className={cn('w-[221px] text-[13px]', classNameSelect)}>
        <div
          aria-describedby={id}
          onClick={handleClick}
          className={cn(
            'max-w-[240px] border border-platinum rounded-xl px-2 py-1 flex items-center justify-between h-8 cursor-pointer',
            {
              'bg-white': theme === 'light',
              'bg-black text-crystal-bell': theme === 'dark',
            }
          )}
        >
          <div className="flex overflow-hidden w-full gap-2">
            {value.map((label) => (
              <div
                key={label.name}
                className={cn(
                  'flex items-center min-w-[100px] bg-white text-xs w-fit px-[4px] py-[2px] rounded-sm line-clamp-1 truncate overflow-hidden',
                  {
                    'text-crystal-bell bg-night-black': theme === 'dark',
                    'text-black': theme === 'light',
                  }
                )}
              >
                {label.name}
              </div>
            ))}
          </div>
          {open ? (
            <ChevronUpIcon className={'h-5 w-5'} />
          ) : (
            <ChevronDownIcon className="h-5 w-5 " />
          )}
        </div>
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <div className="mx-2 mb-2 mt-4">
              <TextField
                autoComplete="off"
                auto
                fullWidth
                size="small"
                id="label-input"
                variant="standard"
                value={searchValue}
                onChange={handleSearch}
                placeholder={placeholderSearch || 'Filter options'}
                InputProps={{
                  autoComplete: 'off',
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className="flex items-center justify-start">
                        <MagnifyingGlassIcon className="w-5 h-5" />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flex flex-col h-full max-h-[300px] overflow-y-auto">
              {memoOptions.map((option) => (
                <li
                  className="flex items-center px-4 gap-2 cursor-pointer"
                  key={option.id}
                  onClick={onClickOption(option)}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={
                      pendingValue.find((label) => label.id === option.id) !==
                      undefined
                    }
                  />
                  <Box className="line-clamp-1 overflow-hidden flex-grow">
                    {option.name}
                  </Box>
                </li>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
