import { Button } from '@mui/material';
import config from '../../config';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';
import DiscordModal from 'Components/DiscordModal';

const ErrorBoundaryLayout = ({ children }) => {
  const navigate = useNavigate();
  const { theme } = useThemeStore();

  const [error, setError] = useState(null);
  const [componentStack, setComponentStack] = useState(null);
  const [openDialogDiscord, setOpenDialogDiscord] = useState(false);

  const onInteraction = async (str) => {
    if (str == 'close') {
      setOpenDialogDiscord(false);
    }
  };

  return (
    <ErrorBoundary
      onError={(error, componentStack) => {
        console.log('Error boundary', error, componentStack);
        setError(error);
        setComponentStack(componentStack);
      }}
      fallback={
        <div className="h-screen flex flex-col gap-2 justify-center items-center py-4">
          <div className="mb-2 text-center">
            <h1
              className={cn('text-4xl font-bold', {
                'text-raisin-black': theme == 'light',
                'text-crystal-bell': theme == 'dark',
              })}
            >
              Oops, something went wrong.
            </h1>
            <h1
              className={cn('text-base', {
                'text-raisin-black': theme == 'light',
                'text-crystal-bell': theme == 'dark',
              })}
            >
              Please try again. If the issue keeps happening, feel free to
              report it on Discord.
            </h1>
          </div>
          {config.enviroment === 'development' && (
            <>
              <pre
                className={cn('text-sm max-w-[600px] text-wrap', {
                  'text-nue-blue': theme == 'light',
                  'text-stargate-shimmer': theme == 'dark',
                })}
              >
                {error && error.toString()}
              </pre>
              <pre
                className={cn(
                  'text-sm max-w-[600px] text-wrap overflow-y-auto bg-ghost-white rounded-md p-2',
                  {
                    'text-raisin-black': true,
                  }
                )}
              >
                {componentStack && JSON.stringify(componentStack)}
              </pre>
            </>
          )}
          <div className="flex justify-between gap-8">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate('/');
                window.location.reload();
              }}
              sx={{
                color: 'white',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '28px',
                fontFamily: 'Figtree',
                borderRadius: '12px',
                textTransform: 'none',
                padding: '4px 16px',
              }}
            >
              Reload
            </Button>
            {config.enviroment === 'production' && (
              <div className="flex mr-2">
                <Button
                  onClick={() => {
                    setOpenDialogDiscord(true);
                  }}
                  endIcon={
                    <img
                      className="h-4 px-1 block"
                      src={
                        theme == 'dark'
                          ? '/discord-mark-white.svg'
                          : '/discord-mark-blue.svg'
                      }
                    />
                  }
                  variant="contained"
                  color="primary"
                  sx={{
                    color: 'white',
                    fontWeight: 700,
                    fontSize: '18px',
                    lineHeight: '28px',
                    fontFamily: 'Figtree',
                    borderRadius: '12px',
                    textTransform: 'none',
                    padding: '4px 16px',
                  }}
                >
                  Discord
                </Button>
              </div>
            )}
          </div>

          {openDialogDiscord ? (
            <DiscordModal onInteraction={onInteraction}></DiscordModal>
          ) : (
            <></>
          )}
        </div>
      }
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryLayout;
