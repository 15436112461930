import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Modal from 'Components/Common/Modal/NewModal';
import { TrashIcon } from '@heroicons/react/24/solid';
import useThemeStore from 'Theme/store';

const DeleteMessage = inject('store')(
  observer(({ onClose, onDelete, open, deleted, setDeleted }) => {
    useEffect(() => {
      setDeleted(false);
    }, [setDeleted]);

    const { theme } = useThemeStore();

    return (
      <Modal
        open={open}
        title="Delete message"
        onClose={onClose}
        onConfirm={onDelete}
        buttonConfirmProps={{
          disabled: deleted,
        }}
        onConfirmBtnText="Delete"
        onConfirmBtnIcon={
          <TrashIcon className="h-[18px] w-[18px]" aria-hidden="true" />
        }
      >
        <div
          className={`font-figtree max-w-64 text-base ${
            theme == 'dark' ? 'text-crystal-bell' : 'text-black'
          } text-center`}
        >
          <div className="mb-2">
            Deleting this output will also remove its paired input.
          </div>

          {deleted ? (
            <div className="text-violet-blue bg-lavender rounded-md p-1 text-center mt-4">
              Information deleted
            </div>
          ) : null}
        </div>
      </Modal>
    );
  })
);
export default DeleteMessage;
