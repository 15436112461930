import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { TranslateContextProvider } from './Context/translate-context';
import { Toaster } from 'react-hot-toast';
// Important fix for 100vh on mobile devices
import useFix100vhError from 'Hooks/useFix100vhError';

const Root = () => {
  useFix100vhError();
  return (
    <TranslateContextProvider>
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #E1E1E5',
            padding: '12px',
            color: '#211E21',
            'font-family': 'figtree',
            background: '#F7F7F8',
          },
          position: 'top-right',
        }}
      />
      <App />
    </TranslateContextProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
