import React, { useEffect, useState } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// React Router Dom
import {
  Navigate,
  useLocation,
  useNavigate,
  useOutlet,
} from 'react-router-dom';
// Components
import useChatStore, {
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  SCRIPT_FOR_TTS,
} from 'Components/Chat/store';
import ComingSoonModal from 'Components/Common/ComingSoonModal/ComingSoonModal';
import { ChatProvider } from 'Context/Chat/Chat.context';
import Header from 'Features/App/Components/Header';
import PowerPanel from 'Features/App/Components/PowerPanel';
import Welcome from 'Features/Login/Welcome';
import ModalMobileUG from 'Components/ModalMobileUG';

const AuthLayout = ({ store }) => {
  const outlet = useOutlet();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showModalTutos, setShowModalTutos] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const currentEvent = useChatStore((state) => state.currentEvent);

  useEffect(() => {
    localStorage.setItem('totalRewards', null);
  }, []);

  useEffect(() => {
    redirectEvent();
  }, [currentEvent]);

  // Connect to general room for a user - Email is the room id
  useEffect(() => {
    const local = localStorage.getItem('profile');
    const profile = JSON.parse(local);
    if (profile) store.subscribeRoom(profile.email);
    if (profile) {
      // userGuiding credentials
      window.userGuiding.identify(profile.customerId, {
        email: profile.email,
        name: profile.fname + ' ' + profile.lname,
        plan: profile.plan,
        scope: profile.scope,
        grandscope: profile.grandscope,
        created_at: formatDate(profile.created),
        count_chats: profile.count_chats,
        count_templates: profile.count_templates,
        count_attachments: profile.count_attachments,
        verified: profile.verified,
        coins: profile.credits,
        welcome_use_one: profile.welcome_use_one,
        welcome_use_two: profile.welcome_use_two,
        welcome_use_three: profile.welcome_use_three,
        welcome_use_other: profile.welcome_use_other,
        welcome_who_item: profile.welcome_who_item,
        welcome_who_size: profile.welcome_who_size,
      });
    }

    // Modal to use UG in desktop
    const showModal = localStorage.getItem('modalMobileUG');
    if (profile) {
      if (profile.beta.kind_of_work && width <= 1024 && !showModal) {
        setShowModalTutos(true);
        localStorage.setItem('modalMobileUG', 'true');
      }
    }
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    return `${year}/${month}/${day}`;
  };

  const redirectEvent = () => {
    switch (currentEvent.name) {
      case EVENT_NEW_CHAT:
      case EVENT_SELECT_CHAT:
      case SCRIPT_FOR_TTS:
        if (location.pathname !== '/chat') navigate('/chat');
        break;
    }
  };

  if (!store.isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <ChatProvider>
      <div className="flex-1 flex w-full h-full">
        <PowerPanel />
        <div className="flex-1 h-full w-full flex flex-col relative">
          <Header open={open} onOpen={() => setOpen(true)} />
          {outlet}
          <ComingSoonModal />
        </div>
      </div>

      {store.profile.continue !== 'beta' ? <Welcome /> : <></>}
      {showModalTutos && (
        <ModalMobileUG
          onContinue={() => setShowModalTutos(false)}
          onClose={() => setShowModalTutos(false)}
        />
      )}
    </ChatProvider>
  );
};
export default inject('store')(observer(AuthLayout));
