// RHF
import { useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
// Theme
import useThemeStore from 'Theme/store';
// React
import toast from 'react-hot-toast';

const VariableSection = ({ selectedPrompt, runningSample, openModal }) => {
  const { register, setValue } = useFormContext();
  //const { register } = useFormContext();
  const { theme } = useThemeStore();

  const [textareaValues, setTextareaValues] = useState({});
  const defaultMaxLength = 10000;
  const [isToastVisible, setToastVisible] = useState(false);

  const handleTextareaChange = (e, maxLength) => {
    const { name, value } = e.target;

    if (typeof maxLength !== 'number') {
      maxLength = defaultMaxLength;
    }

    if (value.length > maxLength) {
      if (!isToastVisible) {
        toast.error(
          'Character limit exceeded: Please edit your input to fit within the allowed limit.',
          {
            icon: '⚠️',
          }
        );
        setToastVisible(true);
      }

      return;
    }

    if (isToastVisible) {
      setToastVisible(false);
    }

    setTextareaValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    //SC ChangesToTest
    setValue(name, value);
  };

  const getPromptMaxLength = (prompt) => {
    if (typeof prompt.maxLength !== 'number') {
      return defaultMaxLength;
    }
    return prompt.maxLength;
  };

  const getCharCount = (text) => {
    if (typeof text !== 'string') {
      return 0;
    }
    return text.length;
  };

  useEffect(() => {
    if (runningSample) {
      selectedPrompt?.prompts.forEach((prompt) => {
        setValue(prompt.attr, prompt.example || '');
        setTextareaValues((prev) => ({
          ...prev,
          [prompt.attr]: prompt.example || '',
        }));
      });
    }
  }, [runningSample]);

  useEffect(() => {
    if (openModal === false) {
      setTextareaValues({});
    }
  }, [openModal]);

  return (
    <div className="font-figtree">
      {selectedPrompt?.prompts
        ?.filter((i) => !['image', 'attachment'].includes(i.type))
        ?.map((prompt, index) => {
          return (
            <div className="mb-4" key={index} style={{ marginRight: '10px' }}>
              <div className="capitalize mb-2 text-base leading-5 font-semibold text-raisin-black dark:text-crystal-bell">
                {prompt?.title}
              </div>
              {['textarea', 'code'].includes(prompt?.type) && (
                <>
                  <textarea
                    name={prompt.attr}
                    className={`w-full ${
                      theme == 'dark'
                        ? 'bg-night-black text-crystal-bell'
                        : 'bg-white text-raisin-black'
                    }  border border-platinum border-solid p-[8px] rounded-md focus:border-violet-blue resize-y`}
                    // style={{ resize: 'none' }}
                    placeholder={prompt.placeholder}
                    minLength={prompt.minLength}
                    required={prompt.required}
                    rows={prompt.type === 'code' ? 10 : 3}
                    cols={prompt.type === 'code' ? 50 : 0}
                    {...register(prompt.attr)}
                    onChange={(e) => handleTextareaChange(e, prompt.maxLength)}
                    value={textareaValues[prompt.attr] || ''}
                  />
                  <div className="flex gap-1 mt-1 text-xs items-center justify-end">
                    <div
                      className={`${
                        theme == 'dark'
                          ? 'dark:text-crystal-bell '
                          : 'text-cool-gray '
                      } font-light`}
                    >
                      <span
                        className={`${
                          getCharCount(textareaValues[prompt.attr] || '') >
                            prompt.maxLength && 'text-nue-blue'
                        }`}
                      >
                        {getCharCount(
                          textareaValues[prompt.attr] || ''
                        ).toLocaleString('en-US')}
                      </span>
                      /{getPromptMaxLength(prompt).toLocaleString('en-US')}{' '}
                      characters
                    </div>
                  </div>
                </>
              )}
              {prompt.type === 'text' && (
                <input
                  name={prompt.attr}
                  className={`w-full border ${
                    theme == 'dark'
                      ? 'bg-night-black text-crystal-bell'
                      : 'bg-white text-raisin-black'
                  }  border-platinum border-solid p-[8px] rounded-md focus:border-violet-blue`}
                  placeholder={prompt.placeholder}
                  maxLength={prompt.maxLength}
                  minLength={prompt.minLength}
                  required={prompt.required}
                  {...register(prompt.attr)}
                  onChange={(e) => handleTextareaChange(e, prompt.maxLength)}
                  value={textareaValues[prompt.attr] || ''}
                />
              )}
              {prompt.type === 'dropdown' && (
                <select
                  name={prompt.attr}
                  className={`w-full border ${
                    theme == 'dark'
                      ? 'bg-night-black text-crystal-bell'
                      : 'bg-white text-raisin-black'
                  }  border-platinum border-solid p-[8px] rounded-md focus:border-violet-blue`}
                  placeholder={prompt.placeholder}
                  required={prompt.required}
                  {...register(prompt.attr)}
                  onChange={(e) => handleTextareaChange(e)}
                  value={textareaValues[prompt.attr] || ''}
                >
                  {prompt.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
              {!['dropdown', 'text', 'textarea', 'code'].includes(
                prompt?.type
              ) &&
                prompt?.type !== 'attachments' && (
                  <input
                    name={prompt.attr}
                    className={`w-full border ${
                      theme == 'dark'
                        ? 'bg-night-black text-crystal-bell'
                        : 'bg-white text-raisin-black'
                    }  border-platinum border-solid p-[8px] rounded-md focus:border-violet-blue`}
                    placeholder={prompt?.placeholder}
                    maxLength={prompt?.maxLength}
                    minLength={prompt?.minLength}
                    required={prompt?.required}
                    {...register(prompt.attr)}
                    onChange={(e) => handleTextareaChange(e, prompt.maxLength)}
                    value={textareaValues[prompt.attr] || ''}
                  />
                )}
            </div>
          );
        })}
    </div>
  );
};

export default VariableSection;
