import { LockClosedIcon } from '@heroicons/react/24/solid';
import { forwardRef } from 'react';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const TextInput = forwardRef(
  ({ endIcon, startIcon, actionIcon, disabled, readonly, ...props }, ref) => {
    const { theme } = useThemeStore();

    const EndIcon = endIcon;
    const StartIcon = startIcon;

    return (
      <div className="relative w-full h-full flex items-center">
        {StartIcon && (
          <span
            className={cn(
              'w-4 h-4 absolute left-4 top-1/2 transform -translate-y-1/2',
              {
                'text-white': theme === 'dark',
                'text-cool-gray': theme !== 'dark',
              }
            )}
          >
            <StartIcon />
          </span>
        )}
        <input
          {...props}
          ref={ref}
          disabled={disabled}
          readOnly={readonly}
          className={cn(
            'rounded-[10px] border-[2.5px] px-3 py-2 w-full font-figtree disabled:font-[500] disabled:cursor-not-allowed ',
            {
              'bg-night-black border-sonic-silver text-white  disabled:bg-umbra disabled:border-night-black disabled:text-sonic-silver':
                theme === 'dark',
              'bg-white text-raisin-black border-lavender disabled:bg-[#C9CADB] disabled:border-lavender disabled:text-cool-gray':
                theme !== 'dark',
              'pl-8': !!startIcon,
            }
          )}
        />
        {EndIcon && (
          <span
            className={cn('absolute right-0 top-[12px]', {
              'text-white': theme === 'dark',
              'text-cool-gray': theme !== 'dark',
            })}
          >
            <EndIcon
              className={cn('size-4', {
                'text-white': theme === 'dark',
                'text-cool-gray': theme !== 'dark',
              })}
            />
          </span>
        )}
        {disabled && (
          <span
            className={cn('absolute right-3 top-[12px]', {
              'text-white': theme === 'dark',
              'text-cool-gray': theme !== 'dark',
            })}
          >
            <LockClosedIcon
              className={cn('size-4', {
                'text-white': theme === 'dark',
                'text-cool-gray': theme !== 'dark',
              })}
            />
          </span>
        )}
        {actionIcon && (
          <span
            className={cn('absolute right-3 top-[12px]', {
              'text-white': theme === 'dark',
              'text-cool-gray': theme !== 'dark',
            })}
          >
            {actionIcon}
          </span>
        )}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
