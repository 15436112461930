import { useEffect } from 'react';
import useThemeStore from 'Theme/store';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/NewModal';
// Icons
import { TrashIcon } from '@heroicons/react/24/solid';

const ClearChat = inject('store')(
  observer(({ onClose, onClear, cleared, setCleared }) => {
    useEffect(() => {
      setCleared(false);
    }, []);

    const { theme } = useThemeStore();

    return (
      <>
        <Modal
          open={true}
          title="Clear chat"
          onClose={onClose}
          onConfirm={onClear}
          buttonConfirmProps={{
            disabled: cleared,
          }}
          onConfirmBtnText="Clear"
          onConfirmBtnIcon={
            <TrashIcon className="h-[18px] w-[18px]" aria-hidden="true" />
          }
        >
          <div
            className={`font-figtree max-w-64 text-base ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-black'
            } text-center`}
          >
            <div className="mb-2">
              Are you sure you want to clear this chat?
            </div>

            {cleared ? (
              <div className="text-violet-blue bg-lavender rounded-md p-1 text-center mt-4">
                Chat Cleared
              </div>
            ) : null}
          </div>
        </Modal>
      </>
    );
  })
);
export default ClearChat;
