import { useEffect, useState } from 'react';

function useDeviceInfo() {
  const [deviceInfo, setDeviceInfo] = useState({
    isIOS: false,
    isAndroid: false,
  });

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(userAgent);

    setDeviceInfo({ isIOS, isAndroid });
  }, []);

  return deviceInfo;
}

export default useDeviceInfo;
