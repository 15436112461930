const { Tooltip } = require('@mui/material');

const CustomToolTip = ({ title, children }) => (
  <Tooltip
    placement="top"
    title={title}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -14],
            },
          },
        ],
      },
    }}
  >
    {children}
  </Tooltip>
);

export default CustomToolTip;
