import { useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';

import {
  CurrencyDollarIcon,
  CreditCardIcon,
  DocumentTextIcon,
  PhotoIcon,
  BanknotesIcon,
  RocketLaunchIcon,
  DocumentPlusIcon,
  ArrowTrendingUpIcon,
  ArrowUpOnSquareIcon,
  BoltIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  BookOpenIcon,
  ChevronDownIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline';
import { CalculatorIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import useLoadingLayoutMyProfile from 'Features/Profile/store';

import MainBody from 'Components/Body';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import KiteIcon from '../Theme/icons/kite';
import EditIcon from '../Theme/icons/edit';
import DiamondIcon from '../Theme/icons/diamond';
import PaperPlaneIcon from '../Theme/icons/paperPlane';
import StarCommentIcon from '../Theme/icons/starComment';
import OpenAIIcon from '../Theme/icons/openai';
import AnthropicIcon from '../Theme/icons/anthropic';
import moment from 'moment';
import PlanActionsDialog from 'Components/PlanActionsDialog';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import useThemeStore from 'Theme/store';
import ModalConfirmClose from 'Features/CreateTemplatesForm/Components/ModalConfirmClose';

const Pricing = inject('store')(
  observer(({ store }) => {
    const [annually, setAnnually] = useState(false);
    const { setLoading } = useLoadingLayoutMyProfile();

    const [rulesPlans, setRulesPlans] = useState([]);
    const [rulesCoinsPack, setRulesCoinsPack] = useState([]);
    const [planGuide, setPlanGuide] = useState([]);
    const [showPlanDialog, setShowPlanDialog] = useState(false);
    const [dialogType, setDialogType] = useState(null);
    const [planSelected, setPlanSelected] = useState(null);
    const [cancelTrialOpen, setCancelTrialOpen] = useState(false);

    const formRef = useRef(null);

    const { theme: themeValue } = useThemeStore();

    useEffect(() => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      if (params.get('freeTrial')) {
        setDialogType('free_trial');
        setShowPlanDialog(true);

        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete('freeTrial');
        window.history.replaceState({}, document.title, currentUrl);
      }
      getRulesPlans();
      getRulesCoinsPack();
      calculateSwitchState();
      getPlanGuide();
    }, []);

    const getRulesPlans = async () => {
      try {
        setLoading(true);
        const res = await store.api.get('/user/constant', {
          params: {
            type: 'princing_plans',
          },
        });
        // console.log(res);
        setRulesPlans(res.data.rules);
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
      }
    };

    const getPlanGuide = async () => {
      try {
        setLoading(true);
        const res = await store.api.get('/user/constant', {
          params: {
            type: 'plan_guide',
          },
        });
        setPlanGuide(res.data.rules);
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
      }
    };

    const getRulesCoinsPack = async () => {
      try {
        setLoading(true);
        const res = await store.api.get('/user/constant', {
          params: {
            type: 'coins_pack',
          },
        });
        setRulesCoinsPack(res.data.rules);
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setLoading(false);
      }
    };

    const getPlan = (abbreviation) => {
      const plan = rulesPlans.reduce((obj, plan) => {
        if (plan.abbreviation === abbreviation) {
          obj = { ...plan };
        }
        return obj;
      }, {});
      return plan;
    };

    const typePlanBasicAppsumo = getPlan('straico_tier1');
    const typePlanStandardAppsumo = getPlan('straico_tier2');
    const typePlanAdvancedAppsumo = getPlan('straico_tier3');
    const typePlanUltimateAppsumo = getPlan('straico_tier4');
    const typePlanDiamondAppsumo = getPlan('straico_tier5');

    const getPack = (abbreviation) => {
      const pack = rulesCoinsPack.reduce((obj, pack) => {
        if (pack.abbreviation === abbreviation) {
          obj = { ...pack };
        }
        return obj;
      }, {});
      return pack;
    };

    const typePackCoinsOndemand = getPack('coins_ondemand');

    const calculateSwitchState = () => {
      if (store.profile.plan.includes('yearly')) {
        setAnnually(true);
      } else {
        setAnnually(false);
      }
    };

    const handleSubmit = (form, plan) => {
      formRef.current = form.current;
      setPlanSelected(plan);
      if (!store.profile.trial_end) {
        form.current.submit();
        return;
      } else {
        if (plan.total != 0) {
          setDialogType('upgrade');
          setShowPlanDialog(true);
        } else {
          if (store.profile.status === 'trialing') {
            setCancelTrialOpen(true);
          } else {
            setDialogType('downgrade');
            setShowPlanDialog(true);
          }
        }
      }
    };

    const onSubmit = () => {
      formRef.current.submit();
    };

    const handleClose = () => {
      setCancelTrialOpen(false);
      onSubmit();
    };

    //----  Logic to go #coins by URL
    const { hash } = useLocation();

    const handleScrollToHash = () => {
      if (hash) {
        setTimeout(() => {
          const id = hash.replace('#', '');

          let intervalId;

          const tryScrolling = () => {
            const element = document.getElementById(id);
            if (element) {
              clearInterval(intervalId); // Limpia el intervalo si el elemento se encuentra
              requestAnimationFrame(() => {
                element.scrollIntoView({ behavior: 'smooth' });
              });
            }
          };

          const element = document.getElementById(id);
          if (element) {
            requestAnimationFrame(() => {
              element.scrollIntoView({ behavior: 'smooth' });
            });
          } else {
            // Define intervalId en un ámbito accesible para tryScrolling
            intervalId = setInterval(tryScrolling, 100); // Reintenta cada 100ms hasta que el elemento se encuentre y desplace la vista.
          }
        }, 1000); // Aumenta el retraso inicial según sea necesario
      }
    };

    useEffect(() => {
      handleScrollToHash();
    }, [hash]);

    useEffect(() => {
      window.addEventListener('hashchange', handleScrollToHash);

      return () => {
        window.removeEventListener('hashchange', handleScrollToHash);
      };
    }, []);
    // ----

    return (
      <>
        <div className="relative z-10 h-full overflow-auto font-figtree text-raisin-black dark:text-crystal-bell">
          <div className="h-[444px] w-full bg-lavender dark:bg-umbra absolute top-0 left-0 -z-10"></div>
          <MainBody className="px-4 py-2 md:px-28">
            <div className="container mx-auto px-4 mb-20">
              {/* Current plan */}
              {store.profile.scope == 'general' ? (
                <div className="mt-6 mb-11 p-5 bg-seasalt dark:bg-lead rounded border border-violet-blue text-sm font-normal">
                  <h1 className="mb-4 uppercase text-base font-bold ">
                    {getPlanHeader(store.profile)}
                  </h1>
                  {
                    <PlanDescription
                      profile={store.profile}
                      stripeUrl={store.baseURL + 'user/stripe/customer-portal'}
                      api={store.api}
                    />
                  }
                </div>
              ) : (
                <></>
              )}

              {/* Header message */}
              <div className="py-5 px-2 md:px-44 text-center not-italic">
                <div className=" text-2xl font-bold mb-2">
                  {getDisclaimerHeader(store.profile)}
                </div>
                <div className="text-battleship-gray dark:text-quicksilver text-sm font-normal">
                  {getDisclaimerDescription(store.profile)}
                </div>
              </div>

              {/* Plans AppSumo */}
              {store.profile.scope == 'appsumo' ? (
                <>
                  <>
                    <HeaderSection
                      categorie="Refill your piggy bank with Extra coins"
                      textColor={
                        themeValue == 'dark' ? 'crystal-bell' : 'raisin-black'
                      }
                    />

                    <Ondemand
                      baseURL={store.baseURL}
                      api={store.api}
                      pack={typePackCoinsOndemand}
                    />
                  </>

                  <div className="mt-5 mb-3 w-full flex justify-center">
                    <ChevronDownIcon className="w-9 h-9" />
                  </div>

                  <div className="mt-4"></div>
                  <HeaderSectionSpecial
                    categorie="UNLOCK THE BEST VALUE WITH Straico, Sumo-ling!"
                    textColor={
                      themeValue == 'dark' ? 'crystal-bell' : 'raisin-black'
                    }
                  />

                  {store.profile.plan == 'straico_tier4' ||
                  store.profile.plan == 'straico_tier5' ? (
                    <>
                      <Grid columns={5}>
                        <BasicAppsumo
                          subscription={store.profile.plan}
                          baseURL={store.baseURL}
                          api={store.api}
                          plan={typePlanBasicAppsumo}
                          uuid={store.profile.appsumo_invoice_item_uuid}
                        />

                        <StandardAppsumo
                          subscription={store.profile.plan}
                          baseURL={store.baseURL}
                          api={store.api}
                          plan={typePlanStandardAppsumo}
                          uuid={store.profile.appsumo_invoice_item_uuid}
                        />

                        <AdvancedAppsumo
                          subscription={store.profile.plan}
                          baseURL={store.baseURL}
                          api={store.api}
                          plan={typePlanAdvancedAppsumo}
                          uuid={store.profile.appsumo_invoice_item_uuid}
                        />

                        <UltimateAppsumo
                          subscription={store.profile.plan}
                          plan={typePlanUltimateAppsumo}
                        />
                        <DiamondAppsumo
                          subscription={store.profile.plan}
                          plan={typePlanDiamondAppsumo}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid columns={3}>
                        <BasicAppsumo
                          subscription={store.profile.plan}
                          baseURL={store.baseURL}
                          api={store.api}
                          plan={typePlanBasicAppsumo}
                          uuid={store.profile.appsumo_invoice_item_uuid}
                        />

                        <StandardAppsumo
                          subscription={store.profile.plan}
                          baseURL={store.baseURL}
                          api={store.api}
                          plan={typePlanStandardAppsumo}
                          uuid={store.profile.appsumo_invoice_item_uuid}
                        />

                        <AdvancedAppsumo
                          subscription={store.profile.plan}
                          baseURL={store.baseURL}
                          api={store.api}
                          plan={typePlanAdvancedAppsumo}
                          uuid={store.profile.appsumo_invoice_item_uuid}
                        />
                      </Grid>
                    </>
                  )}

                  <div className="mt-5 mb-3 w-full flex justify-center">
                    <ChevronDownIcon className="w-9 h-9" />
                  </div>

                  <HeaderSectionSpecial
                    categorie="FEATURES INCLUDED IN ALL PLANS"
                    textColor={
                      themeValue == 'dark' ? 'crystal-bell' : 'raisin-black'
                    }
                  />

                  <Grid columns={1}>
                    <AllFeatures />
                  </Grid>

                  <div className="mt-12"></div>
                </>
              ) : (
                <></>
              )}

              <div className="hidden">
                <div className="text-vista-blue"></div>
                <div className="text-rose-quartz"></div>
                <div className="text-old-rose"></div>
                <div className="text-burnt-sienna"></div>
              </div>

              {/* Plans General */}
              {store.profile.scope == 'general' ? (
                <>
                  {/* Promotion */}
                  {store.profile.sale5000 ? (
                    <>
                      <HeaderSection
                        categorie="Sale"
                        textColor={
                          themeValue == 'dark' ? 'crystal-bell' : 'raisin-black'
                        }
                        borderColor="platinum"
                      />

                      <Promotion
                        baseURL={store.baseURL}
                        api={store.api}
                        pack={typePackCoinsOndemand}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {/* OnDemand */}
                  {store.profile.plan != 'free' && (
                    <>
                      <HeaderSection
                        categorie="Refill your piggy bank with Extra coins"
                        textColor={
                          themeValue == 'dark' ? 'crystal-bell' : 'raisin-black'
                        }
                      />

                      <Ondemand
                        baseURL={store.baseURL}
                        api={store.api}
                        pack={typePackCoinsOndemand}
                      />

                      <div className="mt-5 mb-3 w-full flex justify-center">
                        <ChevronDownIcon className="w-9 h-9" />
                      </div>
                    </>
                  )}

                  <HeaderSection
                    categorie="Choose a plan"
                    textColor={
                      themeValue == 'dark' ? 'crystal-bell' : 'raisin-black'
                    }
                  />

                  <div className="flex justify-between items-center mt-1">
                    <div className="form-control">
                      <FormControlLabel
                        className="uppercase text-raisin-black dark:text-crystal-bell not-italic"
                        control={
                          <CustomSwitch
                            onChange={(e) => setAnnually(e.target.checked)}
                            value={annually}
                            defaultChecked={annually}
                            sx={{ m: 1 }}
                          />
                        }
                        label={
                          <div className="text-sm">
                            {annually
                              ? 'Annual Billing (save up to 16%)'
                              : 'Billed Monthly'}
                          </div>
                        }
                      />
                    </div>

                    {/* Powered by stripe */}
                    {store.profile.scope == 'general' ? (
                      <>
                        <div className="flex justify-end">
                          <span className="text-sm mt-1.5 mr-2"></span>
                          <label className="flex items-center space-x-4">
                            <a
                              href="https://stripe.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className="h-8"
                                src="/stripe.png"
                                alt="stripe"
                              />
                            </a>
                          </label>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex justify-end">
                          <span className="text-sm mt-1.5 mr-2"></span>
                          <label className="flex items-center space-x-4 mt-4">
                            <a
                              href="https://appsumo.com/products/straico"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                className="h-5"
                                src="/AppSumo-Select.svg"
                                alt="AppSumo"
                              />
                            </a>
                          </label>
                        </div>
                      </>
                    )}
                  </div>

                  {store.profile.reference_1pr ? (
                    <Grid columns={1}>
                      <DiscountOff code={store.profile.reference_1pr} />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  <GridFix>
                    {rulesPlans
                      .filter(
                        (plan) =>
                          plan.scope === 'general' &&
                          (plan.abbreviation == 'free' ||
                            plan.periodicity ==
                              (annually ? 'yearly' : 'monthly'))
                      )
                      .map((plan, index) => (
                        <PlanCard
                          key={index}
                          subscription={store.profile.plan}
                          baseURL={store.baseURL}
                          api={store.api}
                          monthly={!annually}
                          plan={plan}
                          reference1PR={
                            store.profile.reference_1pr ? true : false
                          }
                          handleSubmit={(form) => handleSubmit(form, plan)}
                        />
                      ))}
                  </GridFix>

                  <div className="mt-5"></div>

                  <div className="mt-5 mb-3 w-full flex justify-center">
                    <ChevronDownIcon className="w-9 h-9" />
                  </div>

                  {/* Plans guide */}
                  <HeaderSection
                    categorie="Your Guide to Choosing the Best Plan For Your Needs"
                    textColor={
                      themeValue == 'dark' ? 'crystal-bell' : 'raisin-black'
                    }
                  />

                  <Features
                    features={planGuide}
                    plans={rulesPlans.filter(
                      (plan) =>
                        plan.scope === 'general' &&
                        (plan.abbreviation == 'free' ||
                          plan.periodicity == (annually ? 'yearly' : 'monthly'))
                    )}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          </MainBody>
        </div>
        {showPlanDialog && (
          <PlanActionsDialog
            type={dialogType}
            onClose={() => setShowPlanDialog(false)}
            onInteraction={onSubmit}
            plan={planSelected}
          />
        )}
        <ModalConfirmClose
          open={cancelTrialOpen}
          onCancel={() => setCancelTrialOpen(false)}
          onClose={handleClose}
          title="Are you sure?"
          description="Be aware that by cancelling your trial, you will forfeit any unused coins and will automatically return to the free tier service."
          confirmButtonText="Accept"
          cancelButtonText="Cancel"
        />
      </>
    );
  })
);

const PlanCard = inject('store')(
  observer(
    ({
      store,
      baseURL,
      api,
      monthly,
      plan,
      subscription,
      reference1PR,
      handleSubmit,
    }) => {
      const formRef = useRef(null);

      const getIcon = () => {
        const type = plan.abbreviation.replace(
          monthly ? '_monthly' : '_yearly',
          ''
        );
        switch (type) {
          case 'free':
            return <KiteIcon className="mb-4" />;
          case 'basic':
            return <EditIcon className="mb-4" />;
          case 'standard':
            return <PaperPlaneIcon className="mb-4" />;
          case 'advanced':
            return <StarCommentIcon className="mb-4" />;
          default:
            return <></>;
        }
      };

      const { theme: themeValue } = useThemeStore();

      return (
        <div className="flex relative ">
          <div
            className={`${
              themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
            } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-quicksilver md:flex relative transform flex-1`}
          >
            {!monthly && plan.total ? (
              <div
                className={`absolute top-4 right-0 p-1 text-center text-white dark:text-umbra rounded-l-md bg-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              >
                <p className="font-bold">
                  <span className="text-xs uppercase font-normal pl-1">
                    {' '}
                    Total{' '}
                  </span>
                  {plan.currency}
                  {reference1PR
                    ? (plan.total * (1 - 0.1)).toFixed(2)
                    : plan.total}
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className="p-4 flex flex-col justify-between w-full">
              <div>
                {getIcon()}
                <div
                  href="#"
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } block text-base leading-tight font-bold mb-2 uppercase`}
                >
                  {plan.abbreviation.replace(
                    monthly ? '_monthly' : '_yearly',
                    ''
                  )}
                </div>
                {reference1PR && plan.abbreviation != 'free' ? (
                  <p className="text-base border-nue-blue border text-nue-blue px-1 w-20 text-center rounded-sm mb-2">
                    10% off
                  </p>
                ) : (
                  <></>
                )}
                <div className="text-4xl font-semibold">
                  {monthly
                    ? plan.currency +
                      '' +
                      (reference1PR
                        ? plan.total * (1 - 0.1)
                        : plan.total
                      ).toFixed(2)
                    : plan.currency +
                      '' +
                      (reference1PR
                        ? (plan.total / 12) * (1 - 0.1)
                        : plan.total / 12
                      ).toFixed(2)}
                  <span className="text-sm font-normal">
                    {plan.total ? ' / Month' : ' Forever'}
                  </span>
                </div>
                <p className="mt-2 text-sm font-semibold">{plan.description}</p>
                <div className="mt-3">
                  {/* Coins */}
                  <div className="py-1 flex items-center border-y border-platinum">
                    <div className="flex-none">
                      <CurrencyDollarIcon
                        className={`w-5 h-5 mr-2 text-${
                          plan.f_color ? plan.f_color : 'green-400'
                        }`}
                      />
                    </div>
                    <div className="flex-auto">
                      <div className="font-semibold text-sm">
                        <span className="font-bold text-xl">
                          {plan.coins.toLocaleString('en-US')}
                        </span>
                        {`  `} monthly coins to use on:
                      </div>
                    </div>
                  </div>

                  {/* Free */}
                  {!plan.total && (
                    <>
                      {/* Open AI GPT3.5 Turbo */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <OpenAIIcon
                            className={`text-vista-blue w-5 h-5 mr-2 fill-current`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            <span className="font-bold">Unlimited words</span>
                            {`  `} in GPT 3.5-Turbo on the web
                          </div>
                        </div>
                      </div>
                      {/* Claude V2*/}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <AnthropicIcon
                            className={`text-vista-blue w-5 h-5 mr-2 fill-current`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            Up to{' '}
                            <span className="font-bold">10K monthly words</span>
                            {`  `} in Claude Instant v1
                          </div>
                        </div>
                      </div>
                      {/* Models */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <BoltIcon
                            className={`w-5 h-5 mr-2 text-vista-blue`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            Over{' '}
                            <span className="font-bold">20K monthly words</span>{' '}
                            across models like Mixtral, Gemini, Mythomax or
                            Llama
                          </div>
                        </div>
                      </div>
                      {/* Files */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <ArrowUpOnSquareIcon
                            className={`w-5 h-5 mr-2 text-vista-blue`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            Upload and query on files in either{' '}
                            <span className="font-bold">
                              pdf, txt, docx, pptx, xlsx, mp3 or mp4
                            </span>{' '}
                            format
                          </div>
                        </div>
                      </div>
                      {/* Rewards */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <ArrowTrendingUpIcon
                            className={`w-5 h-5 mr-2 text-vista-blue`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            Earn coins through our{' '}
                            <span className="font-bold">rewards program</span>
                          </div>
                        </div>
                      </div>
                      {/* Credit card */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <CreditCardIcon
                            className={`w-5 h-5 mr-2 text-vista-blue`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            <span className="font-bold">No credit card </span>{' '}
                            needed
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* Premium */}
                  {plan.total != 0 && (
                    <>
                      {/* Open AI GPT3.5 Turbo */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <OpenAIIcon
                            className={`text-${
                              plan.f_color ? plan.f_color : 'green-400'
                            } w-5 h-5 mr-2 fill-current`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            <span className="font-bold">Unlimited words</span>
                            {`  `} in GPT 3.5-Turbo on the web
                          </div>
                        </div>
                      </div>
                      {/* Open AI GPT4 */}
                      {plan.words_chat_gpt4 ? (
                        <div className="flex items-center my-2">
                          <div className="flex-none">
                            <OpenAIIcon
                              className={`text-${
                                plan.f_color ? plan.f_color : 'green-400'
                              } w-5 h-5 mr-2 fill-current`}
                            />
                          </div>
                          <div className="flex-auto">
                            <div className="font-normal text-xs">
                              Up to{' '}
                              <span className="font-bold">
                                {(plan.words_chat_gpt4 / 1000).toFixed(0) + 'K'}{' '}
                                monthly words
                              </span>
                              {`  `} in GPT-4o
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Claude 3 - Opus*/}
                      {plan.words_chat_claude3_opus ? (
                        <div className="flex items-center my-2">
                          <div className="flex-none">
                            <AnthropicIcon
                              className={`text-${
                                plan.f_color ? plan.f_color : 'green-400'
                              } w-5 h-5 mr-2 fill-current`}
                            />
                          </div>
                          <div className="flex-auto">
                            <div className="font-normal text-xs">
                              Up to{' '}
                              <span className="font-bold">
                                {(plan.words_chat_claude3_opus / 1000).toFixed(
                                  0
                                ) + 'K'}{' '}
                                monthly words
                              </span>
                              {`  `} in Claude 3 Opus
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Claude 3 - Sonnet*/}
                      {plan.words_chat_claude3_sonnet ? (
                        <div className="flex items-center my-2">
                          <div className="flex-none">
                            <AnthropicIcon
                              className={`text-${
                                plan.f_color ? plan.f_color : 'green-400'
                              } w-5 h-5 mr-2 fill-current`}
                            />
                          </div>
                          <div className="flex-auto">
                            <div className="font-normal text-xs">
                              Up to{' '}
                              <span className="font-bold">
                                {(
                                  plan.words_chat_claude3_sonnet / 1000
                                ).toFixed(0) + 'K'}{' '}
                                monthly words
                              </span>
                              {`  `} in Claude 3 Sonnet
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Claude V2.1*/}
                      {plan.words_chat_anthropic ? (
                        <div className="flex items-center my-2">
                          <div className="flex-none">
                            <AnthropicIcon
                              className={`text-${
                                plan.f_color ? plan.f_color : 'green-400'
                              } w-5 h-5 mr-2 fill-current`}
                            />
                          </div>
                          <div className="flex-auto">
                            <div className="font-normal text-xs">
                              Up to{' '}
                              <span className="font-bold">
                                {(plan.words_chat_anthropic / 1000).toFixed(0) +
                                  'K'}{' '}
                                monthly words
                              </span>
                              {`  `} in Claude V2.1
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Models*/}
                      {plan.words_chat_models ? (
                        <div className="flex items-center my-2">
                          <div className="flex-none">
                            <BoltIcon
                              className={`w-5 h-5 mr-2 text-${
                                plan.f_color ? plan.f_color : 'green-400'
                              }`}
                            />
                          </div>
                          <div className="flex-auto">
                            <div className="font-normal text-xs">
                              Over{' '}
                              <span className="font-bold">
                                {(plan.words_chat_models / 1000).toFixed(0) <
                                1000
                                  ? (plan.words_chat_models / 1000).toFixed(0)
                                  : (plan.words_chat_models / 1000000).toFixed(
                                      0
                                    )}
                                {plan.words_chat_models / 1000 > 1000
                                  ? 'M'
                                  : 'K'}{' '}
                                monthly words
                              </span>
                              {`  `} across 10+ models like Gemini, Mixtral,
                              Mythomax or Perplexity
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Images */}
                      {plan.images ? (
                        <div className="flex items-center my-2">
                          <div className="flex-none">
                            <PhotoIcon
                              className={`w-5 h-5 mr-2 text-${
                                plan.f_color ? plan.f_color : 'green-400'
                              }`}
                            />
                          </div>
                          <div className="flex-auto">
                            <div className="font-normal text-xs">
                              Up to{' '}
                              <span className="font-bold">
                                {plan.images.toLocaleString('en-US')} monthly
                                images
                              </span>{' '}
                              with Stable Diffusion XL or DALL·E 3
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Images vision */}
                      {plan.images_vision ? (
                        <div className="flex items-center my-2">
                          <div className="flex-none">
                            <MagnifyingGlassIcon
                              className={`w-5 h-5 mr-2 text-${
                                plan.f_color ? plan.f_color : 'green-400'
                              }`}
                            />
                          </div>
                          <div className="flex-auto">
                            <div className="font-normal text-xs">
                              Up to{' '}
                              <span className="font-bold">
                                {plan.images_vision.toLocaleString('en-US')}{' '}
                                monthly image analyses
                              </span>{' '}
                              using GPT-4 Vision
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* Files */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <ArrowUpOnSquareIcon
                            className={`w-5 h-5 mr-2 text-${
                              plan.f_color ? plan.f_color : 'green-400'
                            }`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            Upload and query on files in either{' '}
                            <span className="font-bold">
                              pdf, txt, docx, pptx, xlsx, mp3 or mp4
                            </span>{' '}
                            format
                          </div>
                        </div>
                      </div>
                      {/* YouTube */}
                      <div className="flex items-center my-2">
                        <div className="flex-none">
                          <GlobeAltIcon
                            className={`w-5 h-5 mr-2 text-${
                              plan.f_color ? plan.f_color : 'green-400'
                            }`}
                          />
                        </div>
                        <div className="flex-auto">
                          <div className="font-normal text-xs">
                            Query on{' '}
                            <span className="font-bold">YouTube videos</span>
                            {' and '}
                            <span className="font-bold">web page URLs</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div>
                {subscription == plan.abbreviation ? (
                  <form className="flex flex-1">
                    <button
                      type="button"
                      className={`bg-lavender dark:bg-ship-grey mt-8 inset-0 shadow-md flex-1 rounded py-1 px-3 text-violet-blue dark:text-tropical-indigo font-bold text-center text-sm transition uppercase hover:opacity-70`}
                    >
                      Your current plan
                    </button>
                  </form>
                ) : (
                  <form
                    ref={formRef}
                    action={
                      baseURL +
                      (plan.total != 0
                        ? 'user/stripe/subscribe'
                        : 'user/stripe/cancel')
                    }
                    method="POST"
                    className="flex flex-1"
                  >
                    <input
                      type="hidden"
                      name="token"
                      value={api.defaults.headers.common['x-access-token']}
                    />
                    <input
                      type="hidden"
                      name="reference1PR"
                      value={reference1PR}
                    />
                    <input
                      type="hidden"
                      name="plan"
                      value={plan.abbreviation}
                    />
                    <input
                      type="hidden"
                      name="priceId"
                      value={plan.stripe_price_id}
                    />
                    <button
                      type="button"
                      onClick={() => handleSubmit(formRef)}
                      className={`bg-[${
                        plan.b_color ? plan.b_color : 'nue-blue'
                      }] mt-8 inset-0 shadow-md flex-1 rounded py-1 px-3 text-white dark:text-umbra font-bold text-center text-sm transition uppercase hover:opacity-70`}
                    >
                      {plan.total != 0
                        ? store.profile.trial_end
                          ? 'upgrade to ' +
                            plan.abbreviation.replace(
                              monthly ? '_monthly' : '_yearly',
                              ''
                            )
                          : `Try for ${process.env.REACT_APP_TRIAL_PERIOD_DAYS} days for $1`
                        : 'downgrade to ' + plan.abbreviation}
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  )
);

const Ondemand = ({ baseURL, api, pack }) => {
  const [coins, setCoins] = useState(0);
  const [step, setStep] = useState(1);
  const [minCoins, setMinCoins] = useState(0);
  const [maxCoins, setMaxCoins] = useState(100000);
  const [priceTable, setPriceTable] = useState([]);
  const [inputError, setInputError] = useState(null);

  const [total, setTotal] = useState();
  const [discount, setDiscount] = useState();

  const calculateTotal = () => {
    if (inputError) {
      setTotal(0);
      return;
    }
    let totalPricePerCoin = 0;
    priceTable.forEach((price) => {
      if (coins >= price.from && coins <= price.to) {
        setTotal(
          parseFloat(
            (totalPricePerCoin += coins * price.price_per_coin)
          ).toFixed(2)
        );
      }
    });
  };

  const calculateDiscount = () => {
    if (inputError) {
      setDiscount(0);
      return;
    }
    priceTable.forEach((price) => {
      if (coins >= price.from && coins <= price.to) {
        setDiscount(price.discount);
      }
    });
  };

  useEffect(() => {
    setStep(pack.step);
    setCoins(pack.default_coins);
    setMinCoins(pack.min_coins);
    setMaxCoins(pack.max_coins);
    setPriceTable(pack.prices ? pack.prices : []);
  }, [pack]);

  useEffect(() => {
    calculateTotal();
    calculateDiscount();
  }, [coins]);

  const { theme: themeValue } = useThemeStore();

  return (
    <div className="flex relative mt-3">
      <div
        className={`${
          themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
        } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-palladium md:flex relative transform flex-1`}
      >
        <form
          action={baseURL + 'user/stripe/ondemand'}
          method="POST"
          className="w-full"
          onKeyDown={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          <div className="flex flex-col gap-4 p-4 w-full">
            <div>
              {/* Title */}
              <div className="flex items-center">
                <div className="flex-none">
                  <CalculatorIcon
                    className={`text-${
                      pack.f_color ? pack.f_color : 'green-500'
                    } w-6 h-6 mr-2 dark:text-crystal-bell`}
                  />
                </div>
                <div className="flex-auto">
                  <div
                    href="#"
                    className={`text-${
                      pack.f_color ? pack.f_color : 'green-500'
                    } text-sm font-bold uppercase dark:text-crystal-bell`}
                  >
                    Pay-as-you-Go
                  </div>
                </div>
              </div>
              {/* Message */}
              <div className="mt-3 mb-2">
                <div className="text-raisin-black dark:text-crystal-bell text-xs font-bold uppercase">
                  HOW MANY COINS DO YOU NEED?
                </div>
                <div className="text-raisin-black dark:text-crystal-bell text-xs font-normal mt-3">
                  Buy from {pack.min_coins?.toLocaleString('en-US')} up to{' '}
                  {pack.max_coins?.toLocaleString('en-US')} coins.
                </div>
              </div>
              {/* Input */}
              <div className="flex flex-col flex-1 mt-1">
                <input
                  value={coins}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value <= maxCoins && value >= minCoins) {
                      setInputError(false);
                      setCoins(value);
                      calculateTotal();
                    } else {
                      setInputError(true);
                      setCoins(value);
                    }
                  }}
                  type="number"
                  placeholder="Coins"
                  min={minCoins}
                  max={maxCoins}
                  step={step}
                  className={`${
                    themeValue == 'dark'
                      ? 'bg-night-black text-crystal-bell'
                      : 'bg-white text-raisin-black'
                  } rounded text-sm px-3 py-1 border inline-block focus:border-violet-blue ${
                    inputError
                      ? 'border-red-400'
                      : 'border-platinum dark:border-umbra'
                  } dark:text-crystal-bell dark:bg-night-black`}
                />
              </div>
              <input
                type="hidden"
                name="token"
                value={api.defaults.headers.common['x-access-token']}
              />
              <input type="hidden" name="plan" value={pack.abbreviation} />
              <input
                type="hidden"
                name="total"
                value={parseFloat(total).toFixed(2)}
              />
              <input type="hidden" name="coins" value={coins} />

              {/* Total */}
              <div className="text-lg text-raisin-black dark:text-crystal-bell font-bold mt-1">
                <span className="text-sm font-normal">Total:</span>{' '}
                {pack.currency}
                {total}{' '}
                {discount > 0 ? (
                  <>
                    <span className="text-xs font-normal">
                      achieving a{' '}
                      <span className="bg-nue-blue text-white px-2 rounded-md font-bold">
                        {discount}%
                      </span>{' '}
                      savings
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="text-cool-gray dark:text-quicksilver text-xs">
              {/* Prices */}
              <p className="uppercase font-bold">Prices</p>
              <div className="hidden md:flex">
                <div className="flex-1">
                  <p className="font-bold">Steps</p>
                </div>
                <div className="flex-1">
                  <p className="font-bold ml-0 md:ml-6">
                    E.g. purchasing 1,000 coins
                  </p>
                </div>
                <div className="flex-1 ml-2">
                  <p className="font-bold">Discount</p>
                </div>
              </div>
              <div className="mt-1">
                {pack.prices?.map((item, index) => (
                  <div key={index}>
                    <div className="my-1 grid md:grid-cols-3 gap-2 md:grid-flow-row items-center flex-wrap sm:grid-cols-1">
                      <div className="flex md:w-40">
                        <div className="flex-none">
                          <CurrencyDollarIcon className="w-4 h-4 mr-2" />
                        </div>
                        <div className="flex-none">
                          <div>
                            <span>
                              {item.from?.toLocaleString('en-US')} to{' '}
                              {item.to?.toLocaleString('en-US')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-none ml-0 md:ml-6">
                          <BanknotesIcon className="w-4 h-4 mr-2" />
                        </div>
                        <div className="flex-auto ml-0.5">
                          <div>
                            <span>
                              <span>
                                {pack.currency}
                                {item.price_per_coin_1000
                                  ?.toFixed(2)
                                  .toLocaleString('en-US')}
                              </span>
                              <span> per 1,000 coins</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="flex-auto">
                          <div>
                            <span>
                              {item.discount > 0 ? (
                                <>
                                  {' '}
                                  <span className="bg-nue-blue text-[10px] text-white px-2 py-0.5 rounded-md font-bold">
                                    {item.discount}% off
                                  </span>{' '}
                                </>
                              ) : (
                                <>-</>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className={`mt-4 py-1 px-4 inset-0 bg-nue-blue shadow-md rounded text-white font-bold text-center text-sm transition hover:opacity-70 uppercase ${
                    total == 0 ? ' opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={total == 0}
                >
                  Go to check out
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const Promotion = ({ baseURL, api, pack }) => {
  const [coins] = useState(5000);
  const [total] = useState(5);

  return (
    <div className="flex relative mt-12">
      <div
        className={`bg-seasalt rounded-md transition hover:shadow-md overflow-hidden md:max-w-1lg text-cool-gray border border-platinum md:flex relative transform  hover:text-battleship-gray flex-1 shadow-md`}
      >
        <div className="flex flex-wrap p-8 w-full">
          <div className="w-full lg:w-2/3">
            <div
              href="#"
              className={`text-${
                pack.f_color ? pack.f_color : 'green-500'
              } block text-lg leading-tight font-bold mb-2 uppercase`}
            >
              Limited Time Discount - On-Demand Coins
            </div>
            <div className="text-4xl text-battleship-gray font-bold">
              5,000 coins for $5.00
            </div>
            <p className="mt-4 text-lg">
              Explore our full AI-powered suite at 54% off!
            </p>
          </div>
          <div className="w-full lg:w-1/3 flex flex-1 justify-end mt-4 lg:mt-0">
            <form
              action={baseURL + 'user/stripe/ondemand'}
              method="POST"
              className="w-52 lg:absolute lg:bottom-12"
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            >
              <div className="flex  items-center">
                <div className="flex-none">
                  <CurrencyDollarIcon
                    className={`w-6 h-6 mr-1 text-${
                      pack.f_color ? pack.f_color : 'green-500'
                    }`}
                  />
                </div>
                <div className="flex-auto">
                  <div>
                    <span className="text-battleship-gray">Coins</span>
                  </div>
                </div>
              </div>

              <div className="text-2xl text-battleship-gray font-bold mt-4 text-right">
                <span className="text-base text-battleship-gray">Total</span>{' '}
                {pack.currency}
                {parseFloat(total).toFixed(2)}
              </div>

              <input
                type="hidden"
                name="token"
                value={api.defaults.headers.common['x-access-token']}
              />
              <input type="hidden" name="plan" value={'coins_ondemand'} />
              <input
                type="hidden"
                name="total"
                value={parseFloat(total).toFixed(2)}
              />
              <input type="hidden" name="coins" value={coins} />

              <button
                type="submit"
                className={`mt-6 inset-0 bg-nue-blue shadow-lg rounded-md p-4 text-white font-bold text-center text-lg transition hover:bg-nue-blue/70 uppercase text-enter w-52 ${
                  total == 0 ? ' opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={total == 0}
              >
                Claim Yours
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const BasicAppsumo = ({ plan, subscription, uuid }) => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div className="flex relative ">
      <div
        className={`${
          themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
        } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-quicksilver md:flex relative transform flex-1`}
      >
        {subscription == 'straico_tier1' ? (
          <MyPlan color={plan.f_color}></MyPlan>
        ) : (
          <></>
        )}

        <div className="p-4 flex-1">
          <EditIcon className="mb-4" />
          <div
            href="#"
            className={`text-${plan.f_color} block text-base leading-tight font-bold mb-2 uppercase`}
          >
            {plan.description}
          </div>
          <div className="text-4xl font-semibold">
            {(plan.currency || ' . ') + '' + (plan.total || ' . ')}
            <span className="text-sm font-normal">/lifetime</span>
          </div>

          <div className="my-4 py-1 flex items-center border-y border-platinum">
            <div className="flex-none">
              <CurrencyDollarIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-semibold text-sm">
                <span className="font-bold text-xl">
                  {plan.coins?.toLocaleString('en-US')}
                </span>
                {`  `} monthly coins to use on:
              </div>
            </div>
          </div>

          {/* Open AI GPT3.5 Turbo */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <OpenAIIcon
                className={`text-${
                  plan.f_color ? plan.f_color : 'green-400'
                } w-5 h-5 mr-2 fill-current`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                <span className="font-bold">Unlimited words</span>
                {`  `} in GPT 3.5-Turbo on the web
              </div>
            </div>
          </div>
          {/* Open AI GPT4 */}
          {plan.words_chat_gpt4 ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <OpenAIIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_gpt4 / 1000).toFixed(0) + 'K'} monthly
                    words
                  </span>
                  {`  `} in GPT-4o
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Opus*/}
          {plan.words_chat_claude3_opus ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_opus / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Opus
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Sonnet*/}
          {plan.words_chat_claude3_sonnet ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_sonnet / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Sonnet
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude V2.1*/}
          {plan.words_chat_anthropic ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_anthropic / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude V2.1
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Models*/}
          {plan.words_chat_models ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <BoltIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Over{' '}
                  <span className="font-bold">
                    {(plan.words_chat_models / 1000).toFixed(0) < 1000
                      ? (plan.words_chat_models / 1000).toFixed(0)
                      : (plan.words_chat_models / 1000000).toFixed(0)}
                    {plan.words_chat_models / 1000 > 1000 ? 'M' : 'K'} monthly
                    words
                  </span>
                  {`  `} across 10+ models like Gemini, Mixtral, Mythomax or
                  Perplexity
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images */}
          {plan.images ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <PhotoIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images.toLocaleString('en-US')} monthly images
                  </span>{' '}
                  with Stable Diffusion XL or DALL·E 3
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images vision */}
          {plan.images_vision ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <MagnifyingGlassIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images_vision.toLocaleString('en-US')} monthly image
                    analyses
                  </span>{' '}
                  using GPT-4 Vision
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Files */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <ArrowUpOnSquareIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Upload and query on files in either{' '}
                <span className="font-bold">
                  pdf, txt, docx, pptx, xlsx, mp3 or mp4
                </span>{' '}
                format
              </div>
            </div>
          </div>
          {/* YouTube */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <GlobeAltIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Query on <span className="font-bold">YouTube videos</span>
                {' and '}
                <span className="font-bold">web page URLs</span>
              </div>
            </div>
          </div>

          {subscription == 'straico_tier1' ? (
            <form className="flex flex-1">
              <button
                type="button"
                className={`mt-8 inset-0 bg-platinum flex-1 rounded-md p-4 text-${plan.f_color} font-bold uppercase text-center text-lg transition text-enter border border-ghost-white cursor-default`}
              >
                my current plan
              </button>
            </form>
          ) : (
            <form
              action={`https://appsumo.com/account/redemption/${uuid}#change-plan`}
              target="_blank"
              className="flex flex-1"
            >
              <button
                type="submit"
                className={`mt-8 inset-0 bg-nue-blue hover:bg-nue-blue/70 shadow-lg flex-1 rounded-md p-4 text-white font-bold text-center text-lg transition text-enter uppercase`}
              >
                Change plan
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const StandardAppsumo = ({ plan, subscription, uuid }) => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div className="flex relative ">
      <div
        className={`${
          themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
        } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-quicksilver md:flex relative transform flex-1`}
      >
        {subscription == 'straico_tier2' ? (
          <MyPlan color={plan.f_color}></MyPlan>
        ) : (
          <></>
        )}

        <div className="p-4 flex-1">
          <PaperPlaneIcon className="mb-4" />
          <div
            href="#"
            className={`text-${plan.f_color} block text-base leading-tight font-bold mb-2 uppercase`}
          >
            {plan.description}
          </div>
          <div className="text-4xl font-semibold">
            {(plan.currency || ' . ') + '' + (plan.total || ' . ')}
            <span className="text-sm font-normal">/lifetime</span>
          </div>
          <div className="my-4 py-1 flex items-center border-y border-platinum">
            <div className="flex-none">
              <CurrencyDollarIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-semibold text-sm">
                <span className="font-bold text-xl">
                  {plan.coins?.toLocaleString('en-US')}
                </span>
                {`  `} monthly coins to use on:
              </div>
            </div>
          </div>

          {/* Open AI GPT3.5 Turbo */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <OpenAIIcon
                className={`text-${
                  plan.f_color ? plan.f_color : 'green-400'
                } w-5 h-5 mr-2 fill-current`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                <span className="font-bold">Unlimited words</span>
                {`  `} in GPT 3.5-Turbo on the web
              </div>
            </div>
          </div>
          {/* Open AI GPT4 */}
          {plan.words_chat_gpt4 ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <OpenAIIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_gpt4 / 1000).toFixed(0) + 'K'} monthly
                    words
                  </span>
                  {`  `} in GPT-4o
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Opus*/}
          {plan.words_chat_claude3_opus ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_opus / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Opus
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Sonnet*/}
          {plan.words_chat_claude3_sonnet ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_sonnet / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Sonnet
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude V2.1*/}
          {plan.words_chat_anthropic ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_anthropic / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude V2.1
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Models*/}
          {plan.words_chat_models ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <BoltIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Over{' '}
                  <span className="font-bold">
                    {(plan.words_chat_models / 1000).toFixed(0) < 1000
                      ? (plan.words_chat_models / 1000).toFixed(0)
                      : (plan.words_chat_models / 1000000).toFixed(0)}
                    {plan.words_chat_models / 1000 > 1000 ? 'M' : 'K'} monthly
                    words
                  </span>
                  {`  `} across 10+ models like Gemini, Mixtral, Mythomax or
                  Perplexity
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images */}
          {plan.images ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <PhotoIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images.toLocaleString('en-US')} monthly images
                  </span>{' '}
                  with Stable Diffusion XL or DALL·E 3
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images vision */}
          {plan.images_vision ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <MagnifyingGlassIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images_vision.toLocaleString('en-US')} monthly image
                    analyses
                  </span>{' '}
                  using GPT-4 Vision
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Files */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <ArrowUpOnSquareIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Upload and query on files in either{' '}
                <span className="font-bold">
                  pdf, txt, docx, pptx, xlsx, mp3 or mp4
                </span>{' '}
                format
              </div>
            </div>
          </div>
          {/* YouTube */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <GlobeAltIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Query on <span className="font-bold">YouTube videos</span>
                {' and '}
                <span className="font-bold">web page URLs</span>
              </div>
            </div>
          </div>

          {subscription == 'straico_tier2' ? (
            <form className="flex flex-1">
              <button
                type="button"
                className={`mt-8 inset-0 bg-platinum flex-1 rounded-md p-4 text-${plan.f_color} font-bold uppercase text-center text-lg transition text-enter border border-ghost-white cursor-default`}
              >
                my current plan
              </button>
            </form>
          ) : (
            <form
              action={`https://appsumo.com/account/redemption/${uuid}#change-plan`}
              target="_blank"
              className="flex flex-1"
            >
              <button
                type="submit"
                className={`mt-8 inset-0 bg-nue-blue hover:bg-nue-blue/70 shadow-lg flex-1 rounded-md p-4 text-white font-bold text-center text-lg transition uppercase`}
              >
                Change plan
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const AdvancedAppsumo = ({ plan, subscription, uuid }) => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div className="flex relative ">
      <div
        className={`${
          themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
        } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-quicksilver md:flex relative transform flex-1`}
      >
        {subscription == 'straico_tier3' ? (
          <MyPlan color={plan.f_color}></MyPlan>
        ) : (
          <></>
        )}

        <div className="p-4 flex-1">
          <StarCommentIcon className="mb-4" />
          <div
            href="#"
            className={`text-${plan.f_color} block text-base leading-tight font-bold mb-2 uppercase`}
          >
            {plan.description}
          </div>
          <div className="text-4xl font-semibold">
            {(plan.currency || ' . ') + '' + (plan.total || ' . ')}
            <span className="text-sm font-normal">/lifetime</span>
          </div>
          <div className="my-4 py-1 flex items-center border-y border-platinum">
            <div className="flex-none">
              <CurrencyDollarIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-semibold text-sm">
                <span className="font-bold text-xl">
                  {plan.coins?.toLocaleString('en-US')}
                </span>
                {`  `} monthly coins to use on:
              </div>
            </div>
          </div>

          {/* Open AI GPT3.5 Turbo */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <OpenAIIcon
                className={`text-${
                  plan.f_color ? plan.f_color : 'green-400'
                } w-5 h-5 mr-2 fill-current`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                <span className="font-bold">Unlimited words</span>
                {`  `} in GPT 3.5-Turbo on the web
              </div>
            </div>
          </div>
          {/* Open AI GPT4 */}
          {plan.words_chat_gpt4 ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <OpenAIIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_gpt4 / 1000000).toFixed(0) + 'M'} monthly
                    words
                  </span>
                  {`  `} in GPT-4o
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Opus*/}
          {plan.words_chat_claude3_opus ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_opus / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Opus
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Sonnet*/}
          {plan.words_chat_claude3_sonnet ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_sonnet / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Sonnet
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude V2.1*/}
          {plan.words_chat_anthropic ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_anthropic / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude V2.1
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Models*/}
          {plan.words_chat_models ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <BoltIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Over{' '}
                  <span className="font-bold">
                    {(plan.words_chat_models / 1000).toFixed(0) < 1000
                      ? (plan.words_chat_models / 1000).toFixed(0)
                      : (plan.words_chat_models / 1000000).toFixed(0)}
                    {plan.words_chat_models / 1000 > 1000 ? 'M' : 'K'} monthly
                    words
                  </span>
                  {`  `} across 10+ models like Gemini, Mixtral, Mythomax or
                  Perplexity
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images */}
          {plan.images ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <PhotoIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images.toLocaleString('en-US')} monthly images
                  </span>{' '}
                  with Stable Diffusion XL or DALL·E 3
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images vision */}
          {plan.images_vision ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <MagnifyingGlassIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images_vision.toLocaleString('en-US')} monthly image
                    analyses
                  </span>{' '}
                  using GPT-4 Vision
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Files */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <ArrowUpOnSquareIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Upload and query on files in either{' '}
                <span className="font-bold">
                  pdf, txt, docx, pptx, xlsx, mp3 or mp4
                </span>{' '}
                format
              </div>
            </div>
          </div>
          {/* YouTube */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <GlobeAltIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Query on <span className="font-bold">YouTube videos</span>
                {' and '}
                <span className="font-bold">web page URLs</span>
              </div>
            </div>
          </div>

          {subscription == 'straico_tier3' ? (
            <form className="flex flex-1">
              <button
                type="button"
                className={`mt-8 inset-0 bg-platinum flex-1 rounded-md p-4 text-${plan.f_color} font-bold uppercase text-center text-lg transition text-enter border border-ghost-white cursor-default`}
              >
                my current plan
              </button>
            </form>
          ) : (
            <form
              action={`https://appsumo.com/account/redemption/${uuid}#change-plan`}
              target="_blank"
              className="flex flex-1"
            >
              <button
                type="submit"
                className={`mt-8 inset-0 bg-nue-blue hover:bg-nue-blue/70 shadow-lg flex-1 rounded-md p-4 text-white font-bold text-center text-lg transition text-enter uppercase`}
              >
                Change plan
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const UltimateAppsumo = ({ plan, subscription }) => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div className="flex relative ">
      <div
        className={`${
          themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
        } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-quicksilver md:flex relative transform flex-1`}
      >
        {subscription == 'straico_tier4' ? (
          <MyPlan color={plan.f_color}></MyPlan>
        ) : (
          <></>
        )}

        <div className="p-4 flex-1">
          <KiteIcon className="mb-4" />
          <div
            href="#"
            className={`text-${plan.f_color} block text-base leading-tight font-bold mb-2 uppercase`}
          >
            {plan.description}
          </div>
          <div className="text-4xl font-semibold">
            {(plan.currency || ' . ') + '' + (plan.total || ' . ')}
            <span className="text-sm font-normal">/lifetime</span>
          </div>
          <div className="my-4 py-1 flex items-center border-y border-platinum">
            <div className="flex-none">
              <CurrencyDollarIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-semibold text-sm">
                <span className="font-bold text-xl">
                  {plan.coins?.toLocaleString('en-US')}
                </span>
                {`  `} monthly coins to use on:
              </div>
            </div>
          </div>

          {/* Open AI GPT3.5 Turbo */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <OpenAIIcon
                className={`text-${
                  plan.f_color ? plan.f_color : 'green-400'
                } w-5 h-5 mr-2 fill-current`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                <span className="font-bold">Unlimited words</span>
                {`  `} in GPT 3.5-Turbo on the web
              </div>
            </div>
          </div>
          {/* Open AI GPT4 */}
          {plan.words_chat_gpt4 ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <OpenAIIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_gpt4 / 1000000).toFixed(0) + 'M'} monthly
                    words
                  </span>
                  {`  `} in GPT-4o
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Opus*/}
          {plan.words_chat_claude3_opus ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_opus / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Opus
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Sonnet*/}
          {plan.words_chat_claude3_sonnet ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_sonnet / 1000000).toFixed(1) +
                      'M'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Sonnet
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude V2.1*/}
          {plan.words_chat_anthropic ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_anthropic / 1000000).toFixed(0) + 'M'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude V2.1
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Models*/}
          {plan.words_chat_models ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <BoltIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Over{' '}
                  <span className="font-bold">
                    {(plan.words_chat_models / 1000).toFixed(0) < 1000
                      ? (plan.words_chat_models / 1000).toFixed(0)
                      : (plan.words_chat_models / 1000000).toFixed(0)}
                    {plan.words_chat_models / 1000 > 1000 ? 'M' : 'K'} monthly
                    words
                  </span>
                  {`  `} across 10+ models like Gemini, Mixtral, Mythomax or
                  Perplexity
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images */}
          {plan.images ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <PhotoIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images.toLocaleString('en-US')} monthly images
                  </span>{' '}
                  with Stable Diffusion XL or DALL·E 3
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images vision */}
          {plan.images_vision ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <MagnifyingGlassIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images_vision.toLocaleString('en-US')} monthly image
                    analyses
                  </span>{' '}
                  using GPT-4 Vision
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Files */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <ArrowUpOnSquareIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Upload and query on files in either{' '}
                <span className="font-bold">
                  pdf, txt, docx, pptx, xlsx, mp3 or mp4
                </span>{' '}
                format
              </div>
            </div>
          </div>
          {/* YouTube */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <GlobeAltIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Query on <span className="font-bold">YouTube videos</span>
                {' and '}
                <span className="font-bold">web page URLs</span>
              </div>
            </div>
          </div>
          {subscription == 'straico_tier4' ? (
            <form className="flex flex-1">
              <button
                type="button"
                className={`mt-8 inset-0 bg-platinum flex-1 rounded-md p-4 text-${plan.f_color} font-bold uppercase text-center text-lg transition text-enter border border-ghost-white cursor-default`}
              >
                my current plan
              </button>
            </form>
          ) : (
            <a
              href="https://straico.com/"
              className="cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className={`mt-8 inset-0 bg-platinum flex-1 rounded-md p-4 text-${plan.f_color} font-bold uppercase text-center text-lg transition text-enter border border-ghost-white cursor-pointer`}
              >
                {' '}
                contact us{' '}
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const DiamondAppsumo = ({ plan, subscription }) => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div className="flex relative ">
      <div
        className={`${
          themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
        } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-quicksilver md:flex relative transform flex-1`}
      >
        {subscription == 'straico_tier5' ? (
          <MyPlan color={plan.f_color}></MyPlan>
        ) : (
          <></>
        )}

        <div className="p-4 flex-1">
          <DiamondIcon className="mb-4" />
          <div
            href="#"
            className={`text-${plan.f_color} block text-base leading-tight font-bold mb-2 uppercase`}
          >
            {plan.description}
          </div>
          <div className="text-4xl font-semibold">
            {(plan.currency || ' . ') + '' + (plan.total || ' . ')}
            <span className="text-sm font-normal">/lifetime</span>
          </div>
          <div className="my-4 py-1 flex items-center border-y border-platinum">
            <div className="flex-none">
              <CurrencyDollarIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-semibold text-sm">
                <span className="font-bold text-xl">
                  {plan.coins?.toLocaleString('en-US')}
                </span>
                {`  `} monthly coins to use on:
              </div>
            </div>
          </div>

          {/* Open AI GPT3.5 Turbo */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <OpenAIIcon
                className={`text-${
                  plan.f_color ? plan.f_color : 'green-400'
                } w-5 h-5 mr-2 fill-current`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                <span className="font-bold">Unlimited words</span>
                {`  `} in GPT 3.5-Turbo on the web
              </div>
            </div>
          </div>
          {/* Open AI GPT4 */}
          {plan.words_chat_gpt4 ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <OpenAIIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_gpt4 / 1000000).toFixed(0) + 'M'} monthly
                    words
                  </span>
                  {`  `} in GPT-4o
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Opus*/}
          {plan.words_chat_claude3_opus ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_opus / 1000).toFixed(0) + 'K'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Opus
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude 3 - Sonnet*/}
          {plan.words_chat_claude3_sonnet ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_claude3_sonnet / 1000000).toFixed(1) +
                      'M'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude 3 Sonnet
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Claude V2.1*/}
          {plan.words_chat_anthropic ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <AnthropicIcon
                  className={`text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  } w-5 h-5 mr-2 fill-current`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {(plan.words_chat_anthropic / 1000000).toFixed(0) + 'M'}{' '}
                    monthly words
                  </span>
                  {`  `} in Claude V2.1
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Models*/}
          {plan.words_chat_models ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <BoltIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Over{' '}
                  <span className="font-bold">
                    {(plan.words_chat_models / 1000).toFixed(0) < 1000
                      ? (plan.words_chat_models / 1000).toFixed(0)
                      : (plan.words_chat_models / 1000000).toFixed(0)}
                    {plan.words_chat_models / 1000 > 1000 ? 'M' : 'K'} monthly
                    words
                  </span>
                  {`  `} across 10+ models like Gemini, Mixtral, Mythomax or
                  Perplexity
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images */}
          {plan.images ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <PhotoIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images.toLocaleString('en-US')} monthly images
                  </span>{' '}
                  with Stable Diffusion XL or DALL·E 3
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Images vision */}
          {plan.images_vision ? (
            <div className="flex items-center my-2">
              <div className="flex-none">
                <MagnifyingGlassIcon
                  className={`w-5 h-5 mr-2 text-${
                    plan.f_color ? plan.f_color : 'green-400'
                  }`}
                />
              </div>
              <div className="flex-auto">
                <div className="font-normal text-xs">
                  Up to{' '}
                  <span className="font-bold">
                    {plan.images_vision.toLocaleString('en-US')} monthly image
                    analyses
                  </span>{' '}
                  using GPT-4 Vision
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* Files */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <ArrowUpOnSquareIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Upload and query on files in either{' '}
                <span className="font-bold">
                  pdf, txt, docx, pptx, xlsx, mp3 or mp4
                </span>{' '}
                format
              </div>
            </div>
          </div>
          {/* YouTube */}
          <div className="flex items-center my-2">
            <div className="flex-none">
              <GlobeAltIcon
                className={`w-5 h-5 mr-2 text-${
                  plan.f_color ? plan.f_color : 'green-400'
                }`}
              />
            </div>
            <div className="flex-auto">
              <div className="font-normal text-xs">
                Query on <span className="font-bold">YouTube videos</span>
                {' and '}
                <span className="font-bold">web page URLs</span>
              </div>
            </div>
          </div>
          {subscription == 'straico_tier5' ? (
            <form className="flex flex-1">
              <button
                type="button"
                className={`mt-8 inset-0 bg-platinum flex-1 rounded-md p-4 text-${plan.f_color} font-bold uppercase text-center text-lg transition text-enter border border-ghost-white cursor-default`}
              >
                my current plan
              </button>
            </form>
          ) : (
            <a
              href="https://straico.com/"
              className="cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              <div
                className={`mt-8 inset-0 bg-platinum flex-1 rounded-md p-4 text-${plan.f_color} font-bold uppercase text-center text-lg transition text-enter border border-ghost-white cursor-pointer`}
              >
                {' '}
                contact us{' '}
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const AllFeatures = () => {
  const { theme: themeValue } = useThemeStore();

  return (
    <div className="flex relative ">
      <div
        className={`${
          themeValue == 'dark' ? 'bg-lead' : 'bg-seasalt'
        } rounded transition hover:shadow-md overflow-hidden md:max-w-1lg border border-platinum dark:border-quicksilver md:flex relative transform flex-1`}
      >
        <div className="p-4 flex-1 font-normal text-sm">
          <div className="divide-y divide divide-platinum dark:divide-quicksilver">
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  An AI chat assistant that works just like{' '}
                  <span className="font-bold">ChatGPT or Claude</span>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  More than{' '}
                  <span className="font-bold">150 prompt templates</span> for
                  different purposes (business and management, marketing and
                  content, language and writing, programming, social media,
                  role-playing, educators, affiliate marketing, SEO expert and
                  personal development among others)
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  The ability to save time by creating{' '}
                  <span className="font-bold">your own prompt templates</span>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  <span className="font-bold">
                    High-quality AI image generation
                  </span>{' '}
                  using Stable Diffusion XL or DALL·E 3
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  AI image generation offers multiple styles and parameters such
                  as{' '}
                  <span className="font-bold">
                    positive and negative prompts
                  </span>{' '}
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  Built-in image editor that makes it easy to{' '}
                  <span className="font-bold">
                    edit multiple images at once
                  </span>{' '}
                  using masking and other features
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  <span className="font-bold">
                    Voice-powered AI chat assistant
                  </span>{' '}
                  with in-chat voice notes using OpenAI Whisper speech-to-text
                  technology
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  Convert chat inputs or outputs into life like spoken audio
                  using{' '}
                  <span className="font-bold">
                    OpenAI text-to-speech technology
                  </span>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  <span className="font-bold">
                    Attach multiple files simultaneously
                  </span>{' '}
                  to your chats and get insightful responses: upload files in
                  pdf, txt, docx, pptx, xlsx, jpg (using GPT-4- Vision), png
                  (using GPT-4-Vision), mp3 or mp4 format
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  Flexibility to choose among{' '}
                  <span className="font-bold">16+ different AI models</span>{' '}
                  simultaneously for the chat assistant based on speed, cost,
                  word capacity, complex reasoning, moderation layers and more
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  Functionality to upload{' '}
                  <span className="font-bold">YouTube or webpage URLs</span> for
                  extracting valuable information and inspiration
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  Organized{' '}
                  <span className="font-bold">chat and template folders</span>{' '}
                  for easy access and management
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  <span className="font-bold">One-year coin rollover</span>{' '}
                  privileges preventing loss of unused coins
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  Use all your coins via{' '}
                  <span className="font-bold">our powerful API</span>{' '}
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex-auto">
                <div>
                  A <span className="font-bold">rewards system</span> that lets
                  you earn coins as you create
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Features = ({ features, plans }) => {
  return (
    <div className="mt-5">
      {/* Header */}
      <div className="grid grid-cols-2 gap-4">
        <div className="hidden lg:block"></div>
        <div
          className={`w-full bg-seasalt dark:bg-lead py-2 mb-1 rounded border border-platinum dark:border-quicksilver grid col-span-2 lg:col-span-1
           lg:grid-cols-${plans.length}`}
        >
          {plans.map((plan, index) => (
            <div key={index} className="text-center">
              <div
                className={`text-${
                  plan.f_color ? plan.f_color : 'green-400'
                } text-sm font-bold uppercase`}
              >
                {plan.abbreviation.replace(
                  plan.periodicity == 'monthly' ? '_monthly' : '_yearly',
                  ''
                )}
              </div>
              <div className="font-semibold text-xs">
                {plan.coins >= 1000
                  ? (plan.coins / 1000).toFixed(0) + 'K'
                  : plan.coins}{' '}
                coins / month
              </div>
            </div>
          ))}
        </div>
      </div>
      {features.map((feature, index) => (
        <div
          key={index}
          className="grid grid-cols-2 gap-4 w-full px-2 py-1 my-1 bg-seasalt dark:bg-lead rounded border border-platinum dark:border-quicksilver text-sm"
        >
          <div dangerouslySetInnerHTML={{ __html: feature.label }} />
          <div className={`grid grid-cols-${plans.length}`}>
            {plans.map((plan, index) => (
              <div key={index} className="flex justify-center items-center">
                {plan.features.includes(feature.abbreviation) ? (
                  <CheckCircleIcon
                    className={`w-5 h-6 mr-5 text-${
                      plan.f_color ? plan.f_color : 'green-400'
                    }`}
                  />
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const DiscountOff = ({ code }) => {
  return (
    <div className="flex relative ">
      <div
        className={`bg-seasalt rounded text-battleship-gray border border-platinum md:flex relative transform  hover:text-cool-gray flex-1`}
      >
        <div className="p-8 flex-1 ">
          <div
            className={`text-raisin-black block text-lg leading-tight font-semibold mb-2 uppercase`}
          >
            Enjoy a built-in 10% benefit with every plan, referral code{' '}
            <span className=" border-violet-blue border text-violet-blue px-1 text-center rounded-sm mb-2">
              {code}
            </span>
          </div>
          <div className="divide-y divide-dashed divide-platinum mt-4">
            <div className="py-2 flex items-center">
              <div className="flex-none">
                <MegaphoneIcon className={`w-6 h-6 mr-2 text-cool-gray`} />
              </div>
              <div className="flex-auto">
                <div>
                  Experience the magic of our affiliate program with a{' '}
                  <span className="font-bold">10% discount</span> applied to
                  each and every plan, designed to enhance your experience
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Grid = ({ children, columns }) => (
  <div className={`grid grid-cols-1 gap-12 mt-4 xl:grid-cols-${columns}`}>
    {children}
  </div>
);

const GridFix = ({ children }) => (
  <div className={`grid grid-cols-1 gap-12 mt-3 xl:grid-cols-4`}>
    {children}
  </div>
);

const HeaderSection = ({ categorie, textColor, borderColor }) => (
  <div
    className={`flex justify-start items-center uppercase tracking-wide text-base text-${textColor} font-bold leading-none`}
  >
    {categorie}
  </div>
);

const HeaderSectionSpecial = ({ categorie, textColor, borderColor }) => (
  <div
    className={`flex justify-start items-center tracking-wide text-base text-${textColor} font-bold leading-none dark:text-snow-100`}
  >
    {categorie}
  </div>
);

const MyPlan = () => (
  <div
    className={`absolute top-8 right-0 py-0.5 px-1 text-center rounded-l-md text-white bg-nue-blue dark:text-graphite-900`}
  >
    <p className="text-sm uppercase">Active</p>
  </div>
);

const MyPlanFix = () => (
  <div
    className={`absolute top-12 right-0 py-0.5 pr-1 pl-1.5 text-center rounded-l-md text-white bg-nue-blue`}
  >
    <p className="text-sm uppercase">Active</p>
  </div>
);

const CustomSwitch = styled(Switch)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  width: 40,
  height: 20,
  padding: 0,
  borderWidth: 1.5,
  borderRadius: 20 / 2,
  borderColor: theme.palette.primary.dark,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1.5,
    transitionDuration: '300ms',
    color: theme.palette.primary.dark,
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: theme.palette.primary.contrastText,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.dark,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15,
    height: 15,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.primary.contrastText,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const getPlanHeader = (profile) => {
  return (
    <>
      {profile.trial_end && moment().isBefore(moment(profile.trial_end)) ? (
        <>
          Your current plan: Trial (
          {profile.plan.replace(/_monthly|_yearly/i, '')})
        </>
      ) : (
        <>Your current plan: {profile.plan.replace(/_monthly|_yearly/i, '')}</>
      )}
    </>
  );
};

const PlanDescription = ({ profile, stripeUrl, api }) => {
  const stripeRef = useRef(null);

  return (
    <>
      <form ref={stripeRef} action={stripeUrl} method="POST" className="hidden">
        <input
          type="hidden"
          name="token"
          value={api.defaults.headers.common['x-access-token']}
        />
      </form>
      {!profile.trial_end ? (
        <>
          {profile.plan == 'free' ? (
            <>
              <p className="mb-2">
                Good news! You're just one step away from unlocking the full
                potential of AI. Click below to start your{' '}
                {process.env.REACT_APP_TRIAL_PERIOD_DAYS}
                -day trial for just $1 and explore the amazing features of our
                premium plans.
              </p>
              <p>
                Activate your{' '}
                <span className="text-violet-blue font-semibold underline">
                  {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial
                </span>{' '}
                for just $1.
              </p>
            </>
          ) : (
            <>
              <p className="mb-2">
                You have{' '}
                <span className="font-bold">
                  {profile.credits.toLocaleString('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  coins left.
                </span>{' '}
                Your subscription{' '}
                {profile.status == 'canceled' ? 'ends' : 'renews'} on{' '}
                <span className="font-bold">
                  {moment(profile.current_period_end).format('MMMM Do, YYYY')}.
                </span>
              </p>
              <p>
                You can manage your subscription and check your billing details
                in{' '}
                <span
                  onClick={() => stripeRef.current.submit()}
                  className="text-violet-blue font-semibold underline cursor-pointer"
                >
                  your Stripe account
                </span>
                .
              </p>
            </>
          )}
        </>
      ) : (
        <>
          {moment().isBefore(moment(profile.trial_end)) ? (
            <>
              <p className="mb-2">
                You are currently in your{' '}
                {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial of{' '}
                <span className="font-bold capitalize">
                  Straico’s {profile.plan.replace(/_monthly|_yearly/i, '')}{' '}
                  Plan.
                </span>{' '}
                This trial allows you to sample to the full extent of Straico's
                powerful features and tools.{' '}
                <span className="font-bold">
                  Your trial period ends on{' '}
                  {moment(profile.trial_end).format('DD/MM/YYYY')}.
                </span>{' '}
                Feel free to explore, innovate, and achieve stellar results
                before deciding on your plan.
              </p>
              <p>
                To handle your subscription details, please visit{' '}
                <span className="text-violet-blue font-semibold underline cursor-pointer">
                  your Stripe account
                </span>
                .
              </p>
            </>
          ) : (
            <>
              {profile.plan == 'free' ? (
                <>
                  <p className="mb-2">
                    We miss you in our premium circle! Ready for something more
                    powerful?{' '}
                    <span className="text-violet-blue font-semibold underline">
                      Upgrade to a paid subscription
                    </span>{' '}
                    to unlock more incredible features and get ahead of your AI
                    journey.
                  </p>
                  <p>No rush! We’ll be waiting for you.</p>
                </>
              ) : (
                <>
                  <p className="mb-2">
                    You have{' '}
                    <span className="font-bold">
                      {profile.credits.toLocaleString('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{' '}
                      coins left.
                    </span>{' '}
                    Your subscription{' '}
                    {profile.status == 'canceled' ? 'ends' : 'renews'} on{' '}
                    <span className="font-bold">
                      {moment(profile.current_period_end).format(
                        'MMMM Do, YYYY'
                      )}
                      .
                    </span>
                  </p>
                  <p>
                    You can manage your subscription and check your billing
                    details in{' '}
                    <span
                      onClick={() => stripeRef.current.submit()}
                      className="text-violet-blue font-semibold underline cursor-pointer"
                    >
                      your Stripe account
                    </span>
                    .
                  </p>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const getDisclaimerHeader = (profile) => {
  return (
    <>
      {!profile.trial_end ? (
        <>
          {profile.plan == 'free'
            ? 'Unlock your creative superpowers today!'
            : 'Take your creativity to the next level!'}
        </>
      ) : (
        <>
          {moment().isBefore(moment(profile.trial_end))
            ? 'Take your creativity to the next level!'
            : 'Step into the world of unlimited creativity!'}
        </>
      )}
    </>
  );
};

const getDisclaimerDescription = (profile) => {
  return (
    <>
      {!profile.trial_end ? (
        <>
          {profile.plan == 'free' ? (
            <>
              <p>
                Start your{' '}
                <span className="text-graphite-800 dark:text-stargate-shimmer font-bold">
                  {process.env.REACT_APP_TRIAL_PERIOD_DAYS}-day trial for just
                  $1
                </span>{' '}
                and amplify your creation abilities.
              </p>
              <p>
                Gain access to premium features engineered to elevate your work
                to new heights.
              </p>
            </>
          ) : (
            <>
              <p>
                Ready to supercharge your creativity?{' '}
                <span className="text-graphite-800 dark:text-stargate-shimmer font-bold">
                  Upgrade your plan or refill coins
                </span>{' '}
                to unlock advanced features that will elevate your work.
              </p>
            </>
          )}
        </>
      ) : (
        <>
          {moment().isBefore(moment(profile.trial_end)) ? (
            <>
              <p>
                Ready to supercharge your creativity?{' '}
                <span className="text-graphite-800 dark:text-stargate-shimmer font-bold">
                  Upgrade your plan or refill coins
                </span>{' '}
                to unlock advanced features that will elevate your work.
              </p>
            </>
          ) : (
            <>
              <p>
                You've experienced the power of our trial. Now,{' '}
                <span className="text-graphite-800 dark:text-stargate-shimmer font-bold">
                  upgrade to a paid subscription
                </span>{' '}
                and continue your creative journey with access to advanced
                features.
              </p>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Pricing;
