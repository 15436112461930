import { XMarkIcon } from '@heroicons/react/24/outline';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { Dialog, IconButton } from '@mui/material';

const Modal = ({
  onClose,
  open,
  children,
  maxWidth = 'md',
  maxHeight,
  title,
  onConfirm,
  onConfirmBtnIcon,
  onConfirmBtnText,
  buttonConfirmProps,
  noMaxWidth = false,
  noPaddingX = false,
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      PaperProps={{
        sx: {
          borderRadius: '21px',
          height: maxHeight ? '100%' : 'initial',
          // minWidth: minWidth ? minWidth : { xs: '350px', md: '550px' },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <div
        className={`font-figtree h-full w-full relative text-cool-gray dark:text-seasalt ${
          !noMaxWidth && 'sm:max-w-lg'
        } ${!noPaddingX && 'px-[30px]'} py-7 flex flex-col gap-5`}
      >
        <div className="absolute top-[6px] right-[6px] group">
          <IconButton onClick={onClose}>
            <XMarkIcon className="h-3 w-3 cursor-pointer text-cool-gray dark:text-seasalt dark:group-hover:text-quicksilver group-hover:text-battleship-gray" />
          </IconButton>
        </div>
        {title && (
          <div className="w-full flex justify-center">
            <div className="text-raisin-black  dark:text-crystal-bell text-[28px] leading-7 font-bold text-center">
              {title}
            </div>
          </div>
        )}
        <div className="flex-1 flex flex-col overflow-auto">{children}</div>
        {onConfirm && (
          <div className="flex w-full items-center justify-center">
            <button
              {...buttonConfirmProps}
              className="flex gap-3 items-center justify-center text-[18px] leading-6 font-semibold rounded-xl px-5 py-2 text-white focus:outline-none bg-nue-blue hover:bg-nue-blue/70"
              onClick={onConfirm}
            >
              {onConfirmBtnIcon ? (
                <>{onConfirmBtnIcon}</>
              ) : (
                <BookmarkIcon className="w-5 h-5 text-white mr-1" />
              )}{' '}
              {onConfirmBtnText ?? 'Save'}
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default Modal;
