import { create } from 'zustand';

const useSmartModeStore = create((set) => ({
  smartMode: localStorage.getItem('smartMode') || 'off',
  hasUsedSmartMode: false,
  setSmartMode: (mode) => {
    localStorage.setItem('smartMode', mode);
    set({ smartMode: mode });
  },
  setHasUsedSmartMode: (value) => set({ hasUsedSmartMode: value }),
}));

export default useSmartModeStore;
