import {
  BoltIcon,
  BookmarkIcon,
  ChatBubbleLeftIcon,
  EyeIcon,
  LinkIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { PlusCircleIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { LoadingButton } from '@mui/lab';
import { Button, Container } from '@mui/material';
import BackButton from '@straico-ui/commons/BackButton';
import BasicAvatar from '@straico-ui/commons/BasicAvatar';
import SocialLinksMap from '@straico-ui/commons/SocialLinksMap';
import LabelIcon from '@straico-ui/forms/LabelIcon';
import SelectInput from '@straico-ui/forms/SelectInput';
import TextAreaInput from '@straico-ui/forms/TextAreaInput';
import TextInput from '@straico-ui/forms/TextInput';
import Tab from 'Components/Common/Tab';
import { SOCIAL_MEDIA_LINKS } from 'Constants/socialMediaIcons';
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import useModal from 'Hooks';
import { inject, observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';
import { cn } from 'utils/styles';

const EditProfile = ({ store }) => {
  const user = store.profile;
  const { theme } = useThemeStore();
  const navigate = useNavigate();

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      hero: user?.hero_alias ?? '',
      bio: user?.bio ?? '',
      catchphrase: user?.catch_phrase ?? '',
      socialLinks:
        user?.social_media_links?.length > 0
          ? user?.social_media_links.map((i) => ({
              username: i?.username,
              url: i?.link,
              type: i?.type,
            }))
          : [],
    },
  });

  const heroAlias = useMemo(() => {
    if (user?.hero_alias !== '') {
      return user.hero_alias;
    }
    return '-- / --';
  }, [user.hero_alias]);

  const onGoBack = () => navigate('/my-profile/detail');
  const goToAvatar = () => navigate('/my-avatar');
  const [submitLoading, setSubmitLoading] = useState(false);

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    try {
      const res = await store.api.put(`/profile/edit`, {
        hero_alias: data?.hero ?? '',
        catch_phrase: data?.catchphrase ?? '',
        bio: data?.bio ?? '',
        social_media_links: data?.socialLinks?.map((i) => ({
          username: i?.username,
          link: i?.url,
          type: i?.type,
        })),
      });

      const dataRes = await res.data.data;
      if (res.status === 201) {
        store.profile.hero_alias = dataRes.hero_alias;
        store.profile.catch_phrase = dataRes.catch_phrase;
        store.profile.bio = dataRes.bio;
        store.profile.social_media_links = dataRes.social_media_links;

        toast.success('Profile updated');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast.error('Error updating profile');
    } finally {
      setSubmitLoading(false);
    }
  };

  const [loadingUseIa, setLoadingUseIa] = useState(false);
  const onUseIa = async () => {
    setLoadingUseIa(true);
    try {
      const res = await store.api.get(`/profile/generate-alias`);

      if (res.status === 200) {
        setValue('hero', res.data?.heroData?.hero_alias ?? '');
        setValue('catchphrase', res?.data?.heroData?.catch_phrase ?? '');
        toast.success('😎 Alias and catchphrase generated, review and save!');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast.error('Error generating alias');
    } finally {
      setLoadingUseIa(false);
    }
  };

  const onDeleteSocialLink = (link) => {
    setValue(
      'socialLinks',
      watch('socialLinks').filter((i) => i.url !== link.url)
    );
  };

  return (
    <Container maxWidth="md" className="overflow-y-auto">
      <BackButton text="Go back to Your Profile" onClick={onGoBack} />
      <form>
        <div
          className={cn('my-4 mb-10', {
            'text-white': theme === 'dark',
            'text-[#211E21]': theme !== 'dark',
          })}
        >
          <h1 className={cn('text-3xl font-bold mb-5')}>Edit Your Profile</h1>
          <div className="flex items-center gap-3">
            <EyeIcon className="size-5" />
            <p className="text-xl ">
              Your superhero profile is visible to others because you have
              public templates. Keep inspiring!
            </p>
          </div>
          <div className="flex w-full mt-10 justify-start items-center gap-4">
            <div>
              <BasicAvatar
                svg={user?.avatar?.svg}
                size={20}
                onEdit={goToAvatar}
              />
            </div>
            <div className="w-full">
              <p className="text-2xl font-bold">{heroAlias}</p>
              <div className="w-full gap-1 flex flex-col">
                <div className="flex items-center w-full">
                  <div className="w-1/5 flex">
                    <LabelIcon
                      icon={BoltIcon}
                      text="Hero alias"
                      className="text-[16px]"
                    />
                  </div>
                  <div className="w-4/5 flex">
                    <TextInput
                      {...register('hero')}
                      placeholder="Create your unique superhero nickname (e.g., spidey123)"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full">
                  <div className="w-1/5 flex">
                    <LabelIcon
                      icon={ChatBubbleLeftIcon}
                      text="Catchphrase:"
                      className="text-[16px]"
                    />
                  </div>
                  <div className="w-4/5 flex">
                    <TextInput
                      {...register('catchphrase')}
                      placeholder="Broadcast your power tagline!"
                    />
                  </div>
                </div>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={onUseIa}
                  loading={loadingUseIa}
                  startIcon={<SparklesIcon className="size-5" />}
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    textTransform: 'initial',
                    width: '100px',
                    padding: '2px',
                    borderRadius: '10px',
                    ml: 3,
                    alignSelf: 'flex-end',
                  }}
                >
                  Use AI
                </LoadingButton>
              </div>
            </div>
          </div>
          <div className="mt-7">
            <p className="text-xl font-bold">Bio</p>
            <TextAreaInput
              {...register('bio')}
              placeholder="Tell us about yourself in 160 characters or less"
              className="mt-2"
              rows={3}
            />
          </div>
          <div className="mt-7 flex flex-col gap-3">
            <p className="text-xl font-bold">Links & Social Media</p>
            <div className="flex flex-wrap gap-3">
              <SocialLinksMap
                variant="outlined"
                onDelete={onDeleteSocialLink}
                socialLinks={watch('socialLinks')}
              />
              <AddNewLink
                setValue={setValue}
                socialLinks={watch('socialLinks')}
              />
            </div>
          </div>

          <div className="w-full mt-3">
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={submitLoading}
              startIcon={<BookmarkIcon className="size-6" />}
              sx={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '18px',
                textTransform: 'initial',
                mt: 4,
              }}
            >
              Save changes
            </LoadingButton>
          </div>
        </div>
      </form>
    </Container>
  );
};

const AddNewLink = ({ setValue, socialLinks }) => {
  const { theme } = useThemeStore();
  const { isOpen, openModal, closeModal } = useModal();

  const { register, handleSubmit, reset } = useForm({
    shouldUseNativeValidation: true,
  });

  const [currentTab, setCurrentTab] = useState(1);
  const TABS = [
    {
      icon: <UserGroupIcon className="w-6 h-6" />,
      text: 'Social Media',
      value: 1,
      render: () => (
        <div className="flex flex-col gap-7">
          <div>
            <label className="font-bold text-sm">Platform</label>
            <SelectInput {...register('platform')}>
              <option value="tiktok">TikTok</option>
              <option value="youtube">Youtube</option>
              <option value="twitter">Twitter</option>
              <option value="linkedin">LinkedIn</option>
            </SelectInput>
          </div>
          <div>
            <label className="font-bold text-sm">User @</label>
            <TextInput
              {...register('username')}
              type="text"
              placeholder="User @"
            />
          </div>
        </div>
      ),
    },
    {
      icon: <LinkIcon className="w-6 h-6" />,
      text: 'Website',
      value: 2,
      render: () => (
        <div className="flex flex-col gap-7">
          <label className="font-bold text-sm">URL</label>
          <TextInput
            {...register('username')}
            type="url"
            placeholder="https://platform.straico.com/chat"
          />
        </div>
      ),
    },
  ];

  const onSubmit = (data) => {
    if (currentTab === 1) {
      const newLink = {
        type: data?.platform,
        username: data?.username,
        url: SOCIAL_MEDIA_LINKS[data?.platform] + data?.username,
      };
      setValue('socialLinks', [...socialLinks, newLink]);
    } else {
      const newLink = {
        type: 'website',
        username: data?.username,
        url: data?.username,
      };
      setValue('socialLinks', [...socialLinks, newLink]);
    }
    reset({ platform: 'tiktok', username: '', url: '' });
  };

  return (
    <>
      <div
        onClick={openModal}
        className={cn(
          ' text-nue-blue p-2 flex w-fit rounded-full items-center cursor-pointer',
          {
            'bg-night-black hover:opacity-80 text-white': theme === 'dark',
            'bg-lavender hover:opacity-80 ': theme !== 'dark',
          }
        )}
      >
        <PlusCircleIcon className="size-5" />
        <p>Add</p>
      </div>
      <CommonModal open={isOpen} handleClose={closeModal} withCloseButton>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-[350px] md:w-[480px] p-7 gap-7"
        >
          <p
            className={`font-lexend line-clamp-1 font-bold uppercase text-md ${
              theme == 'dark' ? 'dark:text-white' : 'text-graphite-900'
            }`}
          >
            Add Links & Social Media
          </p>
          <div className={cn('flex gap-3 justify-center')}>
            {TABS.map((t, index) => (
              <Tab
                key={index}
                icon={t.icon}
                text={t.text}
                value={t.value}
                currentValue={currentTab}
                onClick={() => setCurrentTab(t.value)}
                className="w-fit rounded-xl min-w-[140px]"
              />
            ))}
          </div>
          <div className="w-full gap-7 flex flex-col">
            {TABS.find((i) => i.value === currentTab).render()}
            <div className="flex items-end justify-end self-end">
              <Button
                type="submit"
                variant="contained"
                size="small"
                startIcon={<BookmarkIcon className="size-5" />}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default inject('store')(observer(EditProfile));
