import { useEffect, useMemo } from 'react';

// Styled
import { ThemeProvider } from 'styled-components';
// Mobx
import { Provider } from 'mobx-react';
// Store
import AppStore from './store';
// Tailwind
import colors from 'tailwindcss/colors';
// Router
import { RouterProvider } from 'react-router-dom';
// Mui
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { themeOptions } from './Theme/mui';
// Styles
import './Theme/global.css';
// Theme Components
import ToggleTheme from 'Components/Common/ToggleTheme';
// Router config
import { router } from 'routes.config';
// Error boundary
import { ErrorBoundary } from 'react-error-boundary';
// TreeDNDChatProvider
import TreeDNDChatProvider from 'Components/Chat/TreeDNDChat/Components/TreeDNDChatProvider';
// Theme store
import useThemeStore from 'Theme/store';
// Mobx store init
if (!window.store) {
  window.store = new AppStore();
}

const MUITheme = ({ children }) => {
  const { theme } = useThemeStore();
  const customTheme = useMemo(() => createTheme(themeOptions(theme)), [theme]);

  return <MuiThemeProvider theme={customTheme}>{children}</MuiThemeProvider>;
};

const App = () => {
  const onError = (error, componentStack) => {
    // eslint-disable-next-line no-console
    console.error(error, componentStack);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const toastDivs = document.querySelectorAll(
        '.toast.toast-top.toast-end.mt-14.items-end'
      );
      toastDivs.forEach((div) => {
        div.style.pointerEvents = 'none';
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ErrorBoundary onError={onError}>
      <TreeDNDChatProvider>
        <Provider store={window.store}>
          <MUITheme>
            <ThemeProvider theme={colors}>
              <div className="bg-seasalt dark:bg-lead w-screen h-screen flex flex-col">
                <a
                  href="https://straico-uqhq.updates.userguiding.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="absolute bottom-0 right-2 text-xs text-raisin-black dark:text-crystal-bell font-figtree z-50 underline">
                    {process.env.REACT_APP_WEB_VERSION}
                  </p>
                </a>
                <RouterProvider router={router} />
              </div>
              <div
                className={`app-container ${localStorage.getItem(
                  'theme'
                )} hidden-toggle`}
              >
                <ToggleTheme />
                <header
                  className={`app-header ${localStorage.getItem('theme')}`}
                ></header>
              </div>
            </ThemeProvider>
          </MUITheme>
        </Provider>
      </TreeDNDChatProvider>
    </ErrorBoundary>
  );
};

export default App;
