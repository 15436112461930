// Mobx
import { observer, inject } from 'mobx-react';
// MUI
import { Button } from '@mui/material';
// Store
import useTreeDNDTemplatesStore from 'Features/FoldersTemplates/Store';
// Toast
import { toast } from 'react-hot-toast';
// Components
import CommonModal from 'Features/CreateTemplatesForm/Components/CommonModal';
import { useChat } from 'Context/Chat/Chat.context';
import useThemeStore from 'Theme/store';
import { TrashIcon } from '@heroicons/react/24/outline';

const DeleteFolder = ({ store }) => {
  const {
    deleteFolderModal: open,
    setDeleteFolderModal: setOpen,
    setSelectedFolder,
    selectedFolder,
  } = useTreeDNDTemplatesStore();
  const { templatesFolders, setTemplatesFolders } = useChat();

  const handleClose = () => {
    setOpen(false);
    setSelectedFolder(null);
  };

  const onDelete = async () => {
    try {
      await store.api.delete(`/template/folders/${selectedFolder._id}`);
      setTemplatesFolders(
        templatesFolders.filter((f) => f._id !== selectedFolder._id)
      );
      toast.success('Folder delete successfully');
    } catch (err) {
      toast.error(err?.data?.message);
    } finally {
      handleClose();
    }
  };

  const { theme } = useThemeStore();

  return (
    <CommonModal
      sx={{
        minHeight: '200px',
      }}
      open={open}
      handleClose={handleClose}
    >
      <div className="flex flex-col gap-5 p-4">
        <div className="w-full flex justify-center">
          <div
            className={`font-figtree line-clamp-1 font-bold text-[28px] leading-7 ${
              theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
            }`}
          >
            Delete template folder
          </div>
        </div>
        <div
          className={`font-figtree w-full max-w-md text-base text-center mx-auto${
            theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
          }`}
        >
          Are you sure you want to delete your folder? If you delete it your
          contained templates will go to the "My templates" directory again.
        </div>
        <div className="flex w-full items-center justify-center">
          <button
            className="flex gap-3 items-center justify-center text-[18px] leading-6 font-semibold rounded-xl px-5 py-2 text-white focus:outline-none bg-nue-blue hover:bg-nue-blue/70"
            onClick={onDelete}
          >
            <TrashIcon className="h-[18px] w-[18px]" aria-hidden="true" />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </CommonModal>
  );
};

export default inject('store')(observer(DeleteFolder));
