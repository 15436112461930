const PDFFileUpload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="30"
    viewBox="0 0 24 30"
    fill="none"
  >
    <path
      d="M1.93125 22.8742C1.0375 22.8742 0.3125 22.1492 0.3125 21.2555C0.3125 20.3617 1.0375 19.6367 1.93125 19.6367C2.825 19.6367 3.55 20.3617 3.55 21.2555C3.55 22.1492 2.825 22.8742 1.93125 22.8742Z"
      fill="#535153"
    />
    <path
      d="M1.93125 19.9434C2.65 19.9434 3.2375 20.5309 3.2375 21.2496C3.2375 21.9684 2.65 22.5559 1.93125 22.5559C1.2125 22.5559 0.625 21.9684 0.625 21.2496C0.625 20.5309 1.2125 19.9434 1.93125 19.9434ZM1.93125 19.3184C0.8625 19.3184 0 20.1871 0 21.2496C0 22.3121 0.86875 23.1809 1.93125 23.1809C2.99375 23.1809 3.8625 22.3121 3.8625 21.2496C3.8625 20.1871 2.99375 19.3184 1.93125 19.3184Z"
      fill="#797779"
    />
    <path
      d="M6.35002 29.6875C4.08752 29.6875 2.25002 27.85 2.25002 25.5875V4.4125C2.24377 2.15 4.08752 0.3125 6.35002 0.3125H15.15L23.1875 8.35625V25.5875C23.1875 27.85 21.35 29.6875 19.0875 29.6875H6.35002Z"
      fill="#EAECE8"
    />
    <path
      d="M15.0187 0.625L22.8749 8.48125V25.5875C22.8749 27.675 21.1749 29.375 19.0874 29.375H6.3499C4.2624 29.375 2.5624 27.675 2.5624 25.5875V4.4125C2.5624 2.325 4.2624 0.625 6.3499 0.625H15.0249M15.2812 0H6.3499C3.9124 0 1.93115 1.975 1.93115 4.4125V25.5875C1.93115 28.025 3.90615 30 6.34365 30H19.0874C21.5249 30 23.4999 28.025 23.4999 25.5875V8.225L15.2812 0Z"
      fill="#797779"
    />
    <path
      d="M17.0312 7.91211C16.2374 7.91211 15.5874 7.26836 15.5874 6.46836V0.755859L22.7437 7.91211H17.0249H17.0312Z"
      fill="#DEE0DA"
    />
    <path
      d="M15.9062 1.50625L21.9937 7.59375H17.0312C16.4062 7.59375 15.8999 7.0875 15.8999 6.4625V1.50625M15.2749 0V6.46875C15.2749 7.4375 16.0624 8.225 17.0312 8.225H23.4999L15.2812 0H15.2749Z"
      fill="#797779"
    />
    <path
      d="M0.3125 12.6508C0.3125 11.732 1.05625 10.9883 1.975 10.9883H20.7938C21.2063 10.9883 21.5375 11.3258 21.5375 11.732V18.257C21.5375 18.6695 21.2 19.0008 20.7938 19.0008H1.975C1.33125 19.0008 0.73125 19.2758 0.3125 19.7195V12.6445V12.6508Z"
      fill="#797779"
    />
    <path
      d="M20.7938 11.3066C21.0312 11.3066 21.225 11.5004 21.225 11.7379V18.2629C21.225 18.5004 21.0312 18.6941 20.7938 18.6941H1.975C1.4875 18.6941 1.025 18.8316 0.625 19.0754V12.6566C0.625 11.9129 1.23125 11.3066 1.975 11.3066H20.7938ZM20.7938 10.6816H1.975C0.8875 10.6816 0 11.5629 0 12.6566V20.9254H0.0375C0.2125 20.0129 1.0125 19.3254 1.975 19.3254H20.7938C21.3813 19.3254 21.85 18.8504 21.85 18.2691V11.7441C21.85 11.1566 21.375 10.6879 20.7938 10.6879V10.6816Z"
      fill="#797779"
    />
    <path
      d="M6.85254 16.93C6.73254 16.93 6.63921 16.895 6.57254 16.825C6.50921 16.755 6.47754 16.6567 6.47754 16.53C6.47754 16.4133 6.51087 16.3183 6.57754 16.245C6.64754 16.1683 6.73921 16.13 6.85254 16.13C6.97254 16.13 7.06421 16.165 7.12754 16.235C7.19421 16.3017 7.22754 16.4 7.22754 16.53C7.22754 16.6467 7.19254 16.7433 7.12254 16.82C7.05587 16.8933 6.96587 16.93 6.85254 16.93Z"
      fill="#F6F7F5"
    />
    <path
      d="M7.91895 16.875V13.375H9.37395C9.57395 13.375 9.75395 13.4233 9.91395 13.52C10.0773 13.6167 10.2073 13.7483 10.3039 13.915C10.4006 14.0817 10.4489 14.2683 10.4489 14.475C10.4489 14.685 10.4006 14.875 10.3039 15.045C10.2073 15.2117 10.0773 15.345 9.91395 15.445C9.75395 15.545 9.57395 15.595 9.37395 15.595H8.56894V16.875H7.91895ZM8.56894 14.995H9.32895C9.41228 14.995 9.48728 14.9717 9.55395 14.925C9.62395 14.8783 9.67895 14.8167 9.71895 14.74C9.76228 14.6633 9.78395 14.5767 9.78395 14.48C9.78395 14.3833 9.76228 14.2983 9.71895 14.225C9.67895 14.1483 9.62395 14.0883 9.55395 14.045C9.48728 13.9983 9.41228 13.975 9.32895 13.975H8.56894V14.995Z"
      fill="#F6F7F5"
    />
    <path
      d="M11.1367 16.875V13.375H12.5417C12.7951 13.375 13.0234 13.4167 13.2267 13.5C13.4334 13.5833 13.6101 13.7033 13.7567 13.86C13.9067 14.0167 14.0201 14.2017 14.0967 14.415C14.1767 14.6283 14.2167 14.865 14.2167 15.125C14.2167 15.385 14.1767 15.6233 14.0967 15.84C14.0201 16.0533 13.9084 16.2383 13.7617 16.395C13.6151 16.5483 13.4384 16.6667 13.2317 16.75C13.0251 16.8333 12.7951 16.875 12.5417 16.875H11.1367ZM11.7867 16.37L11.7367 16.275H12.5167C12.6801 16.275 12.8251 16.2483 12.9517 16.195C13.0817 16.1417 13.1917 16.065 13.2817 15.965C13.3717 15.865 13.4401 15.745 13.4867 15.605C13.5334 15.4617 13.5567 15.3017 13.5567 15.125C13.5567 14.9483 13.5334 14.79 13.4867 14.65C13.4401 14.5067 13.3701 14.385 13.2767 14.285C13.1867 14.185 13.0784 14.1083 12.9517 14.055C12.8251 14.0017 12.6801 13.975 12.5167 13.975H11.7217L11.7867 13.89V16.37Z"
      fill="#F6F7F5"
    />
    <path
      d="M14.9209 16.875V13.375H15.5709V16.875H14.9209ZM15.2209 15.44V14.85H17.0259V15.44H15.2209ZM15.2209 13.975V13.375H17.2409V13.975H15.2209Z"
      fill="#F6F7F5"
    />
  </svg>
);

export default PDFFileUpload;
