import { create } from 'zustand';
// Events
const EVENT_NEW_CHAT = 'on_new_chat';
const EVENT_SELECT_CHAT = 'on_select_chat';
const SCRIPT_FOR_TTS = 'script_for_tts';
const LOADING_FAV_TOOLS = 'loading_fav_tools';
const CAPABILITIES_CHANGE = 'capabilities_change';

const useChatStore = create((set, get) => ({
  // States
  chatList: [],
  selectedChat: {
    index: null,
    chat: {
      _id: null,
      messages: [],
    },
  },
  loadingChats: false,
  totalPages: 1,
  currentPage: 1,
  totalChats: 0,
  chatWords: 0,
  refFile: undefined,
  powerPanelOpen: false,
  selectedModels: [],
  showModelsDialog: false,
  currentEvent: {
    name: null,
    params: {},
  },
  modelsList: [],

  // Functions
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }));
  },
  setTotalChats: (totalChats) => {
    set(() => ({
      totalChats,
    }));
  },
  setCurrentPage: (currentPage) => {
    set(() => ({
      currentPage,
    }));
  },
  selectChat: (chatId) => {
    set((state) => ({
      selectedChat: {
        index: (state.chatList.length ? state.chatList : []).findIndex(
          (chat) => chat._id === chatId
        ),
        chat: (state.chatList.length ? state.chatList : []).find(
          (chat) => chat._id === chatId
        ),
      },
    }));
  },
  setSelectedChat: (chat) => {
    set((state) => ({
      selectedChat: {
        ...state.selectedChat,
        chat,
      },
    }));
  },
  cleanSelectedChat: () => {
    set(() => ({
      selectedChat: {
        index: null,
        chat: {
          _id: null,
          messages: [],
        },
      },
    }));
  },
  setChatsList: (newChatData) => {
    set(() => ({
      chatList: newChatData,
    }));
  },
  setLoadingChats: (loading) => {
    set(() => ({
      loadingChats: loading,
    }));
  },
  cleanChatList: () => {
    set(() => ({
      chatList: [],
    }));
  },
  setChatWords: (chatWords) => {
    set(() => ({
      chatWords,
    }));
  },
  setRefFile: (refFile) => {
    set(() => ({
      refFile,
    }));
  },
  updateRefFile: (updateFunc) => {
    set((state) => ({
      refFile: updateFunc(state.refFile),
    }));
  },
  setPowerPanelOpen: (powerPanelOpen) => {
    set(() => ({
      powerPanelOpen,
    }));
  },
  updateSelectedModels: (updateFunc) => {
    set((state) => ({
      selectedModels: updateFunc(state.selectedModels),
    }));
  },
  setShowModelsDialog: (showModelsDialog) => {
    set(() => ({
      showModelsDialog,
    }));
  },
  addEvent: (event, params = {}) => {
    set(() => ({
      currentEvent: {
        name: event,
        params: params,
      },
    }));
  },
  removeEvent: () => {
    set(() => ({
      currentEvent: {
        name: null,
        params: {},
      },
    }));
  },
  runEvent: async (callback) => {
    const { currentEvent } = get();
    if (currentEvent && currentEvent.name) {
      Object.entries(currentEvent.params).length === 0
        ? await callback()
        : await callback(currentEvent.params);
      get().removeEvent();
    }
  },
  setModelsList: (modelsList) => {
    set(() => ({
      modelsList,
    }));
  },
}));

export default useChatStore;

export {
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  SCRIPT_FOR_TTS,
  LOADING_FAV_TOOLS,
  CAPABILITIES_CHANGE,
};
