import { useEffect } from 'react';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/Modal';
// Icons
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const ModelWarningDialog = ({ data, onClose, onContinue }) => {
  const onConfirm = () => {
    onContinue();
  };

  return (
    <>
      <Modal
        open={true}
        title="Get More from Straico on Desktop!"
        iconTitle={
          <ExclamationTriangleIcon
            className="h-5 w-5 text-eggplant-700"
            aria-hidden="true"
          />
        }
        onClose={onClose}
        onConfirm={onConfirm}
        buttonConfirmProps={{}}
        onConfirmBtnText="Got It!"
        onConfirmBtnIcon={<></>}
      >
        <div className="my-4">
          <div className="dialog-content-container-grade overflow-auto pr-2">
            <div className="mt-1">
              <label className="text-[14px] font-barlow">
                Enjoying Straico on mobile? The desktop experience offers more!
                Dive into exclusive desktop guides for maximum benefits.
              </label>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ModelWarningDialog;
