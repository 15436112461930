import { useMemo } from 'react';
// Icons
import {
  BoltIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
// Mobx
import { inject, observer } from 'mobx-react';
// Store
import useCreateTemplatesStore from 'Features/CreateTemplatesForm/Store';
import useThemeStore from 'Theme/store';
import useTemplateModalStore from '../../store';
// Context
import { useChat } from 'Context/Chat/Chat.context';
// Toast
import { toast } from 'react-hot-toast';

function reverseText(texto) {
  return texto.replace(/\$\{([^}]+)\}/g, '{{$1}}');
}

const HOCAdvancedSection = ({ closeTemplateModal, store }) => {
  // Context
  const {
    selectedTool, // Template base info
    promptValue, // Template prompt value
    getToolsAndFilterSidebar, // Function to get the tools and filter the sidebar
  } = useChat();
  // Store
  const { setDeleteTemplateModal, setToDelete } = useTemplateModalStore();
  const { setDefaultData, setEdit, setOpenModal } = useCreateTemplatesStore();

  const OPTIONS = [
    {
      label: 'Edit',
      icon: <PencilSquareIcon className="w-6 h-6" />,
      onClick: () => {
        // Generate data to edit the template
        const data = {
          id: selectedTool?._id,
          default_model: selectedTool?.default_model?.id,
          multi_default_models:
            selectedTool?.multi_default_models?.length > 0
              ? selectedTool?.multi_default_models.map((model) => model.id)
              : [],
          name: selectedTool?.title,
          description: selectedTool?.desc,
          promptVariables: selectedTool?.prompts[0]
            ? selectedTool?.prompts[0]?.prompts
                ?.map((item) => {
                  return {
                    variable: item?.attr,
                    name: item?.title,
                    type: item?.type,
                    options: item?.options?.map((option, index) => {
                      return {
                        id: index,
                        title: option?.label,
                        value: option?.value,
                      };
                    }),
                    placeholder: item?.placeholder,
                    maxLength: item?.maxLength,
                  };
                })
                .filter(
                  (item) => !['attachment', 'image'].includes(item?.type)
                ) ?? []
            : [],
          variables: selectedTool?.prompts[0]
            ? selectedTool?.prompts[0]?.prompts
                ?.map((item) => {
                  return {
                    variable: item?.attr,
                    name: item?.title,
                    type: item?.type,
                    options: item?.options?.map((option, index) => {
                      return {
                        id: index,
                        title: option?.label,
                        value: option?.value,
                      };
                    }),
                    placeholder: item?.placeholder,
                    maxLength: item?.maxLength,
                  };
                })
                .filter((item) => ['attachment', 'image'].includes(item?.type))
            : [],
          prompt: reverseText(promptValue),
          location: selectedTool?.parent_folder ?? '',
          files: selectedTool?.files ?? [],
          is_public: selectedTool?.public,
          slug: selectedTool?.slug,
          seo_title: selectedTool?.seo_title,
          meta_description: selectedTool?.meta_description,
          keywords: selectedTool?.keywords,
        };
        // Function to set the data and open the modal
        setDefaultData(data);
        setEdit(true);
        setOpenModal(true);
        closeTemplateModal();
      },
    },
    {
      label: 'Duplicate',
      icon: <BoltIcon className="w-6 h-6" />,
      onClick: async () => {
        if (
          selectedTool?._id == '65805947d92e370a3d5625c6' ||
          selectedTool?._id == '66c786d7e26442bc593e575d'
        ) {
          toast.error(
            'You cannot duplicate image generation templates. Try duplicating a different template.'
          );
          return;
        }
        try {
          await store.api.post('/tool/clone/' + selectedTool?._id, {
            customCategory: 'My Tools',
          });
          await getToolsAndFilterSidebar('');
          toast.success(
            'Template duplicated, check your "My Templates" section'
          );
        } catch (error) {
          toast.error('Error duplicating template, try again later');
        }
      },
    },
    {
      label: 'Delete',
      icon: <TrashIcon className="w-6 h-6" />,
      onClick: () => {
        setToDelete(selectedTool);
        setDeleteTemplateModal(true);
        closeTemplateModal();
      },
    },
  ];

  const options = useMemo(() => {
    if (selectedTool?.exclusive_straico)
      return OPTIONS.filter(
        (option) => !['Delete', 'Edit'].includes(option.label)
      );
    return OPTIONS;
  }, [selectedTool]);

  return <AdvancedSection options={options} />;
};

export const AdvancedSection = ({ options }) => {
  const { theme } = useThemeStore();

  return (
    <div
      className={`grid grid-cols-${
        options.length ?? 1
      } gap-4 font-figtree px-8`}
    >
      {options.map((option, index) => {
        return (
          <div
            key={index}
            onClick={option.onClick}
            className={`flex justify-center items-center m-auto py-5 px-2 cursor-pointer rounded-xl gap-3 text-lg leading-6 font-semibold w-40 ${
              theme == 'dark'
                ? 'text-crystal-bell bg-tropical-indigo'
                : 'text-violet-blue bg-lavender'
            }`}
          >
            {option.icon}
            {option.label}
          </div>
        );
      })}
      {options.length === 0 && (
        <p
          className={`text-center ${
            theme == 'dark' ? 'text-crystal-bell' : 'text-raisin-black'
          }`}
        >
          No actions available
        </p>
      )}
    </div>
  );
};

export default inject('store')(observer(HOCAdvancedSection));
