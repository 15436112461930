function SvgComponent(props) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M2.15 17.85C1.46667 17.05 0.9375 16.1542 0.5625 15.1625C0.1875 14.1708 0 13.1167 0 12C0 9.5 0.875 7.375 2.625 5.625C4.375 3.875 6.5 3 9 3H9.2L7.6 1.4L9 0L13 4L9 8L7.575 6.575L9.15 5H9C7.06667 5 5.41667 5.68333 4.05 7.05C2.68333 8.41667 2 10.0667 2 12C2 12.85 2.1375 13.65 2.4125 14.4C2.6875 15.15 3.075 15.825 3.575 16.425L2.15 17.85ZM8 17.5C8 17.1167 7.87083 16.7375 7.6125 16.3625C7.35417 15.9875 7.06667 15.5958 6.75 15.1875C6.43333 14.7792 6.14583 14.3542 5.8875 13.9125C5.62917 13.4708 5.5 13 5.5 12.5C5.5 11.5333 5.84167 10.7083 6.525 10.025C7.20833 9.34167 8.03333 9 9 9C9.96667 9 10.7917 9.34167 11.475 10.025C12.1583 10.7083 12.5 11.5333 12.5 12.5C12.5 13 12.3708 13.4708 12.1125 13.9125C11.8542 14.3542 11.5667 14.7792 11.25 15.1875C10.9333 15.5958 10.6458 15.9875 10.3875 16.3625C10.1292 16.7375 10 17.1167 10 17.5H8ZM8 20V18.5H10V20H8ZM15.85 17.85L14.425 16.425C14.925 15.825 15.3125 15.15 15.5875 14.4C15.8625 13.65 16 12.85 16 12C16 10.9 15.7708 9.87917 15.3125 8.9375C14.8542 7.99583 14.225 7.20833 13.425 6.575L14.85 5.15C15.8167 5.98333 16.5833 6.9875 17.15 8.1625C17.7167 9.3375 18 10.6167 18 12C18 13.1167 17.8125 14.1708 17.4375 15.1625C17.0625 16.1542 16.5333 17.05 15.85 17.85Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgComponent;
