import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Tooltip,
} from '@mui/material';
import { motion } from 'framer-motion';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useThemeStore from 'Theme/store';
// Mobx
import { inject, observer } from 'mobx-react';
// Icons
import { icons as modelIcons } from '../../../Theme/icons/modelsIcons/index';
import {
  ChatBubbleLeftRightIcon,
  PencilSquareIcon,
  PhotoIcon,
  PlusCircleIcon,
  SpeakerWaveIcon,
  StarIcon,
} from '@heroicons/react/24/outline';
import {
  PhotoIcon as PhotoIconSolid,
  SparklesIcon,
  NewspaperIcon,
  CommandLineIcon,
  ClipboardDocumentListIcon,
  BriefcaseIcon,
  RectangleStackIcon,
  AcademicCapIcon,
} from '@heroicons/react/24/solid';
import { BookOpenIcon } from '@heroicons/react/24/solid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModelsIcon from '../../../Theme/icons/modelsIcon';
import StraicoSymbolIcon from '../../../Theme/icons/straicoSymbol';
import TemplatesIcon from '../../../Theme/icons/templatesIcon';
// Components
import ConversationSide from 'Components/Chat/ConversationSide/ConversationSide';
import PromptSide from 'Components/Chat/PromptSide/PromptSide';
import useChatStore, {
  CAPABILITIES_CHANGE,
  EVENT_NEW_CHAT,
  EVENT_SELECT_CHAT,
  LOADING_FAV_TOOLS,
  SCRIPT_FOR_TTS,
} from 'Components/Chat/store';
import Loader from 'Components/Loader';
import CreateTemplatesForm from 'Features/CreateTemplatesForm';
import { useChat } from '../../../Context/Chat/Chat.context';

const PowerPanel = ({ store }) => {
  const { theme: themeValue } = useThemeStore();

  const [openModels, setOpenModels] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [loadingFavTools, setLoadingFavTools] = useState(false);
  const [favTools, setFavTools] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const powerPanelOpen = useChatStore((state) => state.powerPanelOpen);
  const setPowerPanelOpen = useChatStore((state) => state.setPowerPanelOpen);
  const loadingChats = useChatStore((state) => state.loadingChats);
  const selectedChat = useChatStore((state) => state.selectedChat);
  const chatList = useChatStore((state) => state.chatList);
  const selectedModels = useChatStore((state) => state.selectedModels);
  const setShowModelsDialog = useChatStore(
    (state) => state.setShowModelsDialog
  );
  const addEvent = useChatStore((state) => state.addEvent);
  const currentEvent = useChatStore((state) => state.currentEvent);
  const runEvent = useChatStore((state) => state.runEvent);

  // Capabilities variables
  const [capabilities, setCapabilities] = useState(
    JSON.parse(localStorage.getItem('capabilities')) || []
  );

  const {
    setSelectedTool,
    toggleShowPromptModal,
    setShowPromptList,
    sidebarTools,
  } = useChat();

  const baseAccordionStyles = () => {
    return {
      backgroundColor: themeValue == 'dark' ? '#1D1C1E' : '#EDEDF2',
      color: themeValue == 'dark' ? '#EFEFEF' : '#211E21',
      '&:before': {
        display: 'none',
      },
      overflow: 'hidden',
    };
  };

  const accordionStyles = () => {
    return {
      ...baseAccordionStyles(),
      '&.Mui-expanded': {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      '& > .MuiCollapse-root': {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 266px)',
      },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper': {
        flex: '1 1 0%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner':
        {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region':
        {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      '& > .MuiCollapse-root > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiAccordion-region > .MuiAccordionDetails-root':
        {
          flex: '1 1 0%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
    };
  };

  const getIcon = (icon) => {
    const Icon = get(modelIcons, icon);
    return Icon ? (
      <Icon className="h-6 w-6" />
    ) : (
      <ModelsIcon className="h-6 w-6 stroke-raisin-black dark:stroke-crystal-bell" />
    );
  };

  const checkCapabilities = (model) => {
    if (model.function_calls && model.function_calls.length) {
      // find capability
      let modelCapabilities = capabilities.filter((capability) =>
        model.function_calls
          .map((func) => func.capability)
          .includes(capability._id)
      );
      return modelCapabilities;
    }
    return null;
  };

  const joinCapabilities = (model) => {
    const capabilitiesList = (checkCapabilities(model) || []).map(
      (c) => c.name + ' capability enabled'
    );
    const text = [model.name, ...capabilitiesList].join('\n');
    return text;
  };

  const activeCapability = (model) => {
    const cap = checkCapabilities(model);

    return cap && cap.length;
  };

  const handleIconClick = (accordionId) => {
    setPowerPanelOpen(true);
    if (accordionId == 'models') {
      setOpenModels(true);
    } else {
      setOpenAccordion(accordionId);
    }
    navigate('/chat');
  };

  const handleAccordionChange = (accordionId) => (event, isExpanded) => {
    setOpenAccordion(isExpanded ? accordionId : false);
  };

  const sortArrayAlphabetically = (array) => {
    return array.sort((a, b) => a.title.localeCompare(b.title));
  };

  const fetchFavs = async () => {
    try {
      setLoadingFavTools(true);
      const userId = store.profile._id;
      const response = await store.api.get(`/tool/favorite-tools/${userId}`);
      setLoadingFavTools(false);
      if (response.data && response.data.tools) {
        setFavTools(sortArrayAlphabetically(response.data.tools));
      } else {
        // Handle scenario when no tools are found or response is not as expected
        console.error(
          'No favorite tools found or unexpected format:',
          response
        );
      }
    } catch (error) {
      console.error('Failed to fetch favorite tools:', error);
      setLoadingFavTools(false);
    }
  };

  const handleSelectPrompt = (id) => {
    const selectedToolLocal = sidebarTools.filter(
      (tool) => tool?._id === id
    )[0];
    setSelectedTool(selectedToolLocal);
    toggleShowPromptModal();
    setShowPromptList(false);
  };

  const onClickTemplate = (tool) => {
    handleSelectPrompt(tool?._id);
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('selectedTool', tool?._id);
    navigate(
      {
        pathname: location.pathname !== '/chat' ? '/chat' : location.pathname,
        search: urlParams.toString(),
      },
      { shallow: true }
    );
  };

  useEffect(() => {
    fetchFavs();
  }, []);

  useEffect(() => {
    if (currentEvent) {
      eventActions(currentEvent);
    }
  }, [currentEvent]);

  const eventActions = (event) => {
    switch (event.name) {
      case LOADING_FAV_TOOLS:
        runEvent(fetchFavs);
        break;
      case CAPABILITIES_CHANGE:
        runEvent(refreshCapabilities);
        break;
    }
  };

  const refreshCapabilities = () => {
    setCapabilities(JSON.parse(localStorage.getItem('capabilities')) || []);
  };

  const openModelsDialog = () => {
    navigate('/chat');
    setShowModelsDialog(true);
  };

  const getToolIconByCategory = (tool) => {
    switch (tool.category) {
      case 'Image Generation':
        return <PhotoIconSolid className="w-5 h-5" />;
      case 'Writing':
        return <ClipboardDocumentListIcon className="w-5 h-5" />;
      case 'Resources':
        return <RectangleStackIcon className="w-5 h-5" />;
      case 'Business':
        return <BriefcaseIcon className="w-5 h-5" />;
      case 'Career Development':
        return <AcademicCapIcon className="w-5 h-5" />;
      case 'Content':
        return <NewspaperIcon className="w-5 h-5" />;
      case 'Programming':
        return <CommandLineIcon className="w-5 h-5" />;
      case 'Image Prompting':
        return <SparklesIcon className="w-5 h-5" />;
      default:
        return <BookOpenIcon className="w-5 h-5" />;
    }
  };

  return (
    <>
      <CreateTemplatesForm />
      <div
        className={`h-full z-50 pt-5 flex flex-col items-center font-figtree ${
          themeValue == 'dark' ? 'bg-umbra' : 'bg-ghost-white'
        } duration-200 max-md:hidden ${powerPanelOpen ? 'w-64' : 'w-14 gap-4'}`}
      >
        <div
          className={`${
            powerPanelOpen && 'w-full mb-4'
          } flex items-center justify-between px-5`}
        >
          <StraicoSymbolIcon
            className={`h-9 fill-nue-blue cursor-pointer select-none duration-500 ${
              powerPanelOpen && 'rotate-[360deg]'
            }`}
            onClick={() => setPowerPanelOpen(!powerPanelOpen)}
          />
          {powerPanelOpen && (
            <>
              <div
                className={`w-8 h-8 flex justify-center items-center rounded-xl cursor-pointer select-none ${
                  themeValue == 'dark'
                    ? 'bg-ship-grey text-stargate-shimmer'
                    : 'bg-lavender text-violet-blue'
                }`}
                onClick={() => addEvent(SCRIPT_FOR_TTS)}
              >
                <SpeakerWaveIcon className="w-[18px] h-[18px]" />
              </div>
              <div
                className={`w-8 h-8 flex justify-center items-center rounded-xl cursor-pointer select-none ${
                  themeValue == 'dark'
                    ? 'bg-ship-grey text-stargate-shimmer'
                    : 'bg-lavender text-violet-blue'
                }`}
                onClick={() => {
                  setPowerPanelOpen(false);
                  navigate('/images');
                }}
              >
                <PhotoIcon className="w-[18px] h-[18px]" />
              </div>
              <div
                className="flex items-center justify-center gap-2 px-2 h-[38px] text-white bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-xl select-none"
                onClick={() => {
                  addEvent(EVENT_NEW_CHAT);
                }}
              >
                <PlusCircleIcon className="h-[21px] w-[21px]" />
                <span>New chat</span>
              </div>
            </>
          )}
        </div>

        {/* new chat */}
        {!powerPanelOpen && (
          <>
            <Tooltip title="New chat" placement="right" arrow>
              <div
                className="flex items-center justify-center w-[34px] h-[34px] bg-nue-blue hover:bg-nue-blue/70 cursor-pointer rounded-xl"
                onClick={() => addEvent(EVENT_NEW_CHAT)}
              >
                <PlusCircleIcon className="h-[21px] w-[21px] text-white" />
              </div>
            </Tooltip>

            {selectedModels.map((model, index) => (
              <Badge
                color="primary"
                variant="dot"
                invisible={!activeCapability(model)}
                key={index}
              >
                <Tooltip
                  className="flex gap-2 cursor-pointer select-none"
                  title={
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {joinCapabilities(model)}
                    </span>
                  }
                  onClick={openModelsDialog}
                  placement="right"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#D4D5E6',
                        color: '#5256A6',
                        '& .MuiTooltip-arrow': {
                          color: '#D4D5E6',
                        },
                      },
                    },
                  }}
                >
                  <div>{getIcon(model.icon)}</div>
                </Tooltip>
              </Badge>
            ))}
          </>
        )}

        {powerPanelOpen ? (
          <div className="w-full h-full flex-1 flex flex-col overflow-auto">
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={baseAccordionStyles()}
              expanded={openModels}
              onChange={(event, isExpanded) => setOpenModels(isExpanded)}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue == 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="models-content"
                id="models-header"
              >
                <ModelsIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell mr-3" />
                <div className="flex-1 font-semibold">Current models</div>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0 }}>
                <div
                  className={`flex gap-2 items-center rounded-xl p-2 border-[1.5px] ${
                    themeValue == 'dark'
                      ? 'bg-night-black border-sonic-silver'
                      : 'bg-white border-cool-gray/35'
                  }`}
                >
                  <div className="flex-1 flex flex-col gap-2">
                    {selectedModels.map((model, index) => (
                      <Badge
                        color="primary"
                        variant="dot"
                        invisible={!activeCapability(model)}
                        key={index}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <Tooltip
                          className="flex gap-2 cursor-pointer select-none"
                          title={
                            <span style={{ whiteSpace: 'pre-line' }}>
                              {joinCapabilities(model)}
                            </span>
                          }
                          placement="right"
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#D4D5E6',
                                color: '#5256A6',
                                '& .MuiTooltip-arrow': {
                                  color: '#D4D5E6',
                                },
                              },
                            },
                          }}
                        >
                          {getIcon(model.icon)}
                          <span className="flex-1 line-clamp-1 text-sm text-agate-violet dark:text-palladium">
                            {model.abbreviation}
                          </span>
                        </Tooltip>
                      </Badge>
                    ))}
                  </div>
                  <div
                    onClick={openModelsDialog}
                    className={`w-8 h-8 flex justify-center items-center ${
                      themeValue == 'dark' ? 'bg-ship-grey' : 'bg-lavender'
                    } rounded-xl cursor-pointer select-none`}
                  >
                    <PencilSquareIcon className="w-[18px] h-[18px] text-violet-blue" />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={accordionStyles()}
              expanded={openAccordion === 'chats'}
              onChange={handleAccordionChange('chats')}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue == 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="chats-content"
                id="chats-header"
              >
                <ChatBubbleLeftRightIcon className="h-[21px] w-[21px] mr-3" />
                <div className="flex-1 font-semibold">My chats</div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <motion.div className="z-10 h-full relative flex-1 flex flex-col overflow-auto">
                  <ConversationSide
                    show={true}
                    onClick={() => addEvent(EVENT_NEW_CHAT)}
                    onSelectChat={(chat) => addEvent(EVENT_SELECT_CHAT, chat)}
                    loading={loadingChats}
                    chatSelectedIndex={selectedChat?.index}
                    chats={chatList}
                    currentChatId={selectedChat?.chat}
                    newDesing
                  />
                </motion.div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={accordionStyles()}
              expanded={openAccordion === 'templates'}
              onChange={handleAccordionChange('templates')}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue == 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="tamplates-content"
                id="tamplates-header"
              >
                <TemplatesIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell mr-3" />
                <div className="flex-1 font-semibold">Templates</div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <PromptSide newDesing />
              </AccordionDetails>
            </Accordion>
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={accordionStyles()}
              expanded={openAccordion === 'favorites'}
              onChange={handleAccordionChange('favorites')}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      fontSize: '0.9rem',
                      color: themeValue == 'dark' ? '#EFEFEF' : '#211E21',
                    }}
                  />
                }
                aria-controls="favotites-content"
                id="favotites-header"
              >
                <StarIcon className="h-[21px] w-[21px] mr-3" />
                <div className="flex-1 font-semibold">Favorite Templates</div>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <div className="flex-1 h-full flex flex-col font-figtree">
                  {loadingFavTools ? (
                    <div className="flex items-center justify-center h-full">
                      <Loader />
                    </div>
                  ) : favTools.length > 0 ? (
                    <>
                      <div className="px-5">
                        <input
                          id="keyword"
                          className={`${
                            themeValue == 'dark'
                              ? 'bg-night-black text-crystal-bell'
                              : 'bg-white text-raisin-black'
                          } text-base focus:outline-none w-full p-0.5 rounded-lg border-2 border-platinum placeholder-battleship-gray`}
                          autoComplete="off"
                          type="text"
                          name="keyword"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="flex-1 overflow-auto px-5">
                        {favTools
                          .filter((tool) =>
                            tool.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((tool) => (
                            <div
                              key={tool._id}
                              className={`flex py-[5px] cursor-pointer select-none items-start gap-2 group text-sm ${
                                themeValue == 'dark'
                                  ? 'text-palladium'
                                  : 'text-agate-violet'
                              }`}
                              onClick={() => onClickTemplate(tool)}
                            >
                              <div className="flex items-center gap-2 w-full py-[2px] hover:bg-platinum dark:hover:bg-ship-grey rounded">
                                {getToolIconByCategory(tool)}
                                <div className="flex-1 line-clamp-1">
                                  {tool.title}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="px-5 text-center">
                      You don't have favorite templates.
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <>
            <Tooltip title="Models" placement="right" arrow>
              <div
                onClick={openModelsDialog}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <ModelsIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell" />
              </div>
            </Tooltip>
            <Tooltip title="Chats" placement="right" arrow>
              <div
                onClick={() => handleIconClick('chats')}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <ChatBubbleLeftRightIcon className="h-[21px] w-[21px] " />
              </div>
            </Tooltip>
            <Tooltip title="Templates" placement="right" arrow>
              <div
                onClick={() => handleIconClick('templates')}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <TemplatesIcon className="h-[21px] w-[21px] stroke-raisin-black dark:stroke-crystal-bell" />
              </div>
            </Tooltip>
            <Tooltip title="Favorites" placement="right" arrow>
              <div
                onClick={() => handleIconClick('favorites')}
                className="flex items-center gap-3 text-raisin-black dark:text-crystal-bell cursor-pointer select-none"
              >
                <StarIcon className="h-[21px] w-[21px]" />
              </div>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
};

export default inject('store')(observer(PowerPanel));
