/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { memo, useState, useEffect } from 'react';
// Plugin's
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
// Components
import useThemeStore from 'Theme/store';
import toast from 'react-hot-toast';
import { HighlightedText } from './HighlightedText';
import { MemoizedReactMarkdown } from './Markdown';
import { MarkdownComponents } from './Markdown.components';
import { Tooltip } from '@mui/material';
import { MemoizedOriginalPrompt } from './OriginalPrompt';
// Icons
import {
  ArrowDownTrayIcon,
  HeartIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/solid';
import useChatStore from 'Components/Chat/store';

const ContentMessage = memo(
  ({ message, userType, onFavoriteFile, onOpenFull, originalPrompt }) => {
    const selectedChat = useChatStore((state) => state.selectedChat);

    const { theme } = useThemeStore();

    const getMessage = (message) => {
      if (typeof message == 'string') {
        return processing(message);
      }

      if (message.length === 1 && message[0].type === 'text') {
        return processing(message[0].text);
      }

      const parseMessage = message.map((frg, index) => {
        switch (frg.type) {
          case 'image_url':
            return (
              <div
                key={index}
                className={`max-h-80 w-full flex justify-center items-center`}
              >
                <div
                  className={`max-h-80 h-fit cursor-pointer relative ${
                    !frg.loading ? 'w-fit' : 'w-full'
                  }`}
                  onClick={() => onOpenFull(getImgTag(frg, true))}
                >
                  {frg.loading ? (
                    <div
                      className={`rounded-[14px] w-full h-80 flex justify-center items-center  ${
                        theme == 'dark' ? 'bg-ship-grey' : 'bg-lavender'
                      }`}
                    >
                      <img
                        className="h-9"
                        key={Math.random()}
                        src="/straico_loader.gif"
                        alt="img ref"
                      />
                    </div>
                  ) : (
                    getImgTag(frg)
                  )}
                  {frg.no_actions || userType == 'user' ? (
                    ''
                  ) : (
                    <div className="absolute p-2 bottom-0 right-0 rounded-b-[14px] w-full bg-gradient-to-t from-raisin-black to-transparent">
                      <div className="relative flex gap-2 justify-end">
                        <div className="relative group">
                          <Tooltip className="hidden absolute font-medium bottom-full right-full group-hover:flex flex-col items-center whitespace-nowrap">
                            <span className="z-50 p-2 text-base leading-none text-raisin-black bg-lavender bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                              Download image
                            </span>
                          </Tooltip>
                          <ArrowDownTrayIcon
                            onClick={(e) =>
                              handleDownload(e, frg.image_url.url)
                            }
                            className="w-5 h-5 font-bold text-white cursor-pointer"
                          />
                        </div>
                        {onFavoriteFile && (
                          <div className="relative group">
                            <Tooltip className="hidden absolute font-medium bottom-full right-full group-hover:flex flex-col items-center whitespace-nowrap">
                              <span className="z-50 p-2 text-base leading-none text-raisin-black bg-lavender bg-opacity-50 shadow-lg text-center backdrop-filter backdrop-blur rounded-md">
                                Add image to your attachments
                              </span>
                            </Tooltip>
                            <HeartIcon
                              id={'favorite' + frg._id}
                              onClick={(e) => handleFavorite(e, frg)}
                              className={`w-5 h-5 font-bold ${
                                frg.favorite ? 'text-nue-blue' : 'text-white'
                              } cursor-pointer`}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          default:
            return processing(frg.text);
        }
      });
      return parseMessage;
    };

    const getImgTag = (frg, maxScreen = false) => {
      return (
        <img
          className={`max-h-${
            maxScreen ? '[calc(100vh_-_2rem)]' : '80'
          } rounded-[14px]`}
          key={Math.random()}
          src={frg.image_url.url}
          alt="img ref"
        />
      );
    };

    const handleDownload = (e, url) => {
      e.stopPropagation();
      const link = document.createElement('a');
      link.href = url;
      link.download = 'image.jpg';
      link.target = '_blanck';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleFavorite = (e, item) => {
      e.stopPropagation();
      if (!item.favorite) {
        let url = item.image_url.url;
        onFavoriteFile({
          url: url,
          name: url.substring(url.lastIndexOf('/') + 1),
          size: null,
          enabled: true,
          type: 'image',
          imageId: item._id,
          chatId: selectedChat.chat._id,
        });

        const heartIcon = document.getElementById('favorite' + item._id);
        if (heartIcon) {
          heartIcon.classList.remove('text-white');
          heartIcon.classList.add('text-nue-blue');
          item.favorite = !item.favorite;
        }
      } else {
        toast.error('The file is currently in your attachments.');
      }
    };

    const customStylesRegex = /""'\s*([^']*)\s*'""/g;

    // Regex to clean the old structure of template message
    const regex_1 = /```"'(.*?)'"```/g;
    const regex_2 = /```""'(.*?)'""```/g;

    const processing = (text) => {
      let formattedMessage = text
        .replace(regex_1, (match) => {
          return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
        })
        .replace(regex_2, (match) => {
          return `""'${match.replaceAll('"', '').replaceAll("'", '')}'""`;
        });

      if (userType === 'user') {
        formattedMessage = formattedMessage.replaceAll(
          customStylesRegex,
          (match) => {
            return `<span id='template'>${match
              .replaceAll('"', '')
              .replaceAll("'", '')}</span>`;
          }
        );
      }
      return formattedMessage;
    };

    const renderMessageContent = (message) => {
      if (!message) {
        return;
      }
      const messageContent = getMessage(message);

      if (typeof messageContent === 'string') {
        return (
          <MemoizedReactMarkdown
            className="prose break-words dark:prose-invert prose-p:leading-relaxed markdown prose-pre:p-0 text-base"
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeRaw]}
            components={MarkdownComponents}
          >
            {messageContent}
          </MemoizedReactMarkdown>
        );
      } else {
        return (
          <div
            className={`py-2 grid gap-2 justify-items-center ${
              messageContent.length > 1 && 'grid-cols-2'
            }`}
          >
            {messageContent}
          </div>
        );
      }
    };

    return (
      <>
        {userType === 'user' ? (
          <>
            <MemoizedOriginalPrompt
              originalPrompt={originalPrompt}
              theme={theme}
            />
            <HighlightedText text={getMessage(message)} />
          </>
        ) : (
          renderMessageContent(message)
        )}
      </>
    );
  }
);

export default ContentMessage;
