const UrlFileUpload = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="url-icon" clipPath="url(#clip0_764_401)">
      <g id="Group 236">
        <path
          id="Vector"
          d="M21.6667 0H4.33333C1.9401 0 0 1.9401 0 4.33333V21.6667C0 24.0599 1.9401 26 4.33333 26H21.6667C24.0599 26 26 24.0599 26 21.6667V4.33333C26 1.9401 24.0599 0 21.6667 0Z"
          fill="#EAECE8"
        />
        <path
          id="Vector_2"
          d="M21.6667 0.619048C23.7157 0.619048 25.381 2.28429 25.381 4.33333V21.6667C25.381 23.7157 23.7157 25.381 21.6667 25.381H4.33333C2.28429 25.381 0.619048 23.7157 0.619048 21.6667V4.33333C0.619048 2.28429 2.28429 0.619048 4.33333 0.619048H21.6667ZM21.6667 0H4.33333C1.93762 0 0 1.93762 0 4.33333V21.6667C0 24.0624 1.93762 26 4.33333 26H21.6667C24.0624 26 26 24.0624 26 21.6667V4.33333C26 1.93762 24.0624 0 21.6667 0Z"
          fill="#797779"
        />
        <path
          id="Vector_3"
          d="M0.321899 4.02386C0.482852 1.95005 2.21619 0.30957 4.33333 0.30957H21.6667C23.7838 0.30957 25.5171 1.95005 25.6781 4.02386H0.321899Z"
          fill="#797779"
        />
        <path
          id="Vector_4"
          d="M21.6667 0.619048C23.5052 0.619048 25.0343 1.96238 25.3314 3.71429H0.668571C0.965714 1.96238 2.49476 0.619048 4.33333 0.619048H21.6667ZM21.6667 0H4.33333C1.93762 0 0 1.93762 0 4.33333H26C26 1.93762 24.0624 0 21.6667 0Z"
          fill="#797779"
        />
        <path
          id="Vector_5"
          d="M21.6667 0.619048C23.7157 0.619048 25.381 2.28429 25.381 4.33333V21.6667C25.381 23.7157 23.7157 25.381 21.6667 25.381H4.33333C2.28429 25.381 0.619048 23.7157 0.619048 21.6667V4.33333C0.619048 2.28429 2.28429 0.619048 4.33333 0.619048H21.6667ZM21.6667 0H4.33333C1.93762 0 0 1.93762 0 4.33333V21.6667C0 24.0624 1.93762 26 4.33333 26H21.6667C24.0624 26 26 24.0624 26 21.6667V4.33333C26 1.93762 24.0624 0 21.6667 0Z"
          fill="#797779"
        />
        <path
          id="Vector_6"
          d="M0 4.33337H26"
          stroke="#797779"
          strokeLinejoin="round"
        />
        <g id="Group 235">
          <path
            id="Vector_7"
            d="M11.2481 16.7638C10.1214 15.6371 10.1214 13.8047 11.2481 12.6781L15.0243 8.90188C16.1509 7.77522 17.9833 7.77522 19.11 8.90188C20.2366 10.0286 20.2366 11.8609 19.11 12.9876L17.3643 14.7333"
            stroke="#797779"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_8"
            d="M14.7519 13.26C15.8786 14.3867 15.8786 16.2191 14.7519 17.3457L10.9757 21.1219C9.84903 22.2486 8.01665 22.2486 6.88998 21.1219C5.76332 19.9952 5.76332 18.1629 6.88998 17.0362L8.6357 15.2905"
            stroke="#797779"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Group 234">
          <path
            id="Vector_9"
            d="M22.5953 3.24996C23.1081 3.24996 23.5238 2.83423 23.5238 2.32139C23.5238 1.80856 23.1081 1.39282 22.5953 1.39282C22.0824 1.39282 21.6667 1.80856 21.6667 2.32139C21.6667 2.83423 22.0824 3.24996 22.5953 3.24996Z"
            fill="#535153"
          />
          <path
            id="Vector_10"
            d="M19.5 3.24996C20.0128 3.24996 20.4286 2.83423 20.4286 2.32139C20.4286 1.80856 20.0128 1.39282 19.5 1.39282C18.9871 1.39282 18.5714 1.80856 18.5714 2.32139C18.5714 2.83423 18.9871 3.24996 19.5 3.24996Z"
            fill="#535153"
          />
          <path
            id="Vector_11"
            d="M16.4048 3.24996C16.9176 3.24996 17.3333 2.83423 17.3333 2.32139C17.3333 1.80856 16.9176 1.39282 16.4048 1.39282C15.8919 1.39282 15.4762 1.80856 15.4762 2.32139C15.4762 2.83423 15.8919 3.24996 16.4048 3.24996Z"
            fill="#535153"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_764_401">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UrlFileUpload;
