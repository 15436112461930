import { cn } from 'utils/styles';

const Tab = ({
  icon,
  text,
  onClick,
  value,
  currentValue,
  disabled,
  className,
}) => {
  const isActive = value === currentValue;
  const handleClick = () => {
    if (disabled) return;
    onClick && onClick(value);
  };
  return (
    <div
      className={cn(
        'flex flex-col gap-1 cursor-pointer transition-all ease-in-out items-center justify-center bg-platinum text-cool-gray dark:bg-ship-grey dark:text-tropical-indigo w-full h-full rounded-xl px-2 py-2 font-semibold',
        {
          'bg-nue-blue dark:bg-nue-blue text-white dark:text-white': isActive,
          'bg-battleship-gray dark:bg-quicksilver cursor-not-allowed': disabled,
        },
        className
      )}
      onClick={handleClick}
    >
      {icon}
      <p className="text-center font-barlow text-sm">{text}</p>
    </div>
  );
};

export default Tab;
