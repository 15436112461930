import { useEffect } from 'react';
import useThemeStore from 'Theme/store';
// Mobx
import { inject, observer } from 'mobx-react';
// Components
import Modal from 'Components/Common/Modal/NewModal';
// Icons
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const ModelWarningDialog = ({ data, onClose, onContinue }) => {
  const onConfirm = () => {
    onContinue(data);
  };

  const { theme } = useThemeStore();

  return (
    <>
      <Modal
        open={true}
        title="Template and Selected Models Mismatch"
        onClose={onClose}
        onConfirm={onConfirm}
        buttonConfirmProps={{}}
        onConfirmBtnText="Continue"
        onConfirmBtnIcon={
          <ExclamationTriangleIcon
            className="h-[18px] w-[18px]"
            aria-hidden="true"
          />
        }
      >
        <div
          className={`font-figtree text-base ${
            theme == 'dark' ? 'text-crystal-bell' : 'text-black'
          } text-center`}
        >
          <div className="mb-2 text-base text-center">
            The template you've selected is designed for a model that's not
            among your current choices. Would you like to continue?
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ModelWarningDialog;
